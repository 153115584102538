import React from "react";
import { Form } from "react-bootstrap";

interface SelectProps<T extends string | number> {
  value: T | undefined;
  onChange: (newValue: T) => void;
  options: { value: T; label: string }[];
}

export function CustomSelect<T extends string | number>({  value, onChange, options }: SelectProps<T>) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = e.target.selectedIndex;
    onChange(options[index].value);
  };

  return (
    <Form.Select value={value ?? ''} onChange={handleChange} style={{ width: "230px" }}>
      {options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
}
