import { Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import SignUpPage from "./SignUpPage";
import DashboardPage from "./DashboardPage";
import LayoutComp from "../components/LayoutComp";
import { LoginContext, BreadCrumbContext, BreadcrumbItem, ToastMsg, ToastMsgContext } from "../utils/Context";
import { useState } from "react";
import { getLoginInfoFromJWT, retrieveJWT } from "../utils/jwt";
import DeckPage from "./DeckPage";
import CreateCardPage from "./CreateCardPage";
import StartDeckPage from "./StartDeckPage";
import ProfilePage from "./ProfilePage";
import QuizHighscorePage from "./QuizHighscorePage";
import QuizPage from "./QuizPage";
import StartQuizPage from "./StartQuizPage";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import EditCardPage from "./EditCardPage";
import CustomToast from "../components/customElements/customToast";
import bg_schwarz from "../assets/img/bg_schwarz.svg";


export default function HomePage() {
  const [loginInfo, setLoginInfo] = useState(getLoginInfoFromJWT(retrieveJWT()));
  const [breadCrumb, setBreadCrumb] = useState<BreadcrumbItem[] | null>(generateBreadcrumbs());
  const [toastMsg, setToastMsg] = useState<ToastMsg | null>(null);

  const updateLoginContext = () => {
    const currentLoginInfo = getLoginInfoFromJWT(retrieveJWT());
    if (currentLoginInfo === null || loginInfo !== currentLoginInfo) {
      setLoginInfo(currentLoginInfo);
    }
  };

  return (
    <div
      style={{
        background: `url(${bg_schwarz})`,
        backgroundPosition: 'center',
        display: 'flex',
        height: '100vh',
        width: '100%',
      }}
    >
      <BreadCrumbContext.Provider value={{ breadCrumb, setBreadCrumb }}>
        <ToastMsgContext.Provider value={{ toastMsg, setToastMsg }}>
          <LoginContext.Provider value={{ loginInfo, setLoginInfo }}>
            <Routes>
              <Route path="*" element={<LoginPage />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path={"/dashboard"} element={<LayoutComp component={<DashboardPage update={updateLoginContext} />} />} />
              <Route path={"/dashboard/learn/:deckID"} element={<LayoutComp component={<StartDeckPage />} />} />
              <Route path={"/dashboard/deck/:deckID"} element={<LayoutComp component={<DeckPage />} />} />
              <Route path={"/dashboard/deck/:deckID/createcard"} element={<LayoutComp component={<CreateCardPage />} />} />
              <Route path={"/dashboard/deck/:deckID/editcard/:cardID"} element={<LayoutComp component={<EditCardPage />} />} />
              <Route path={"/dashboard/quiz"} element={<LayoutComp component={<QuizPage />} />} />
              <Route path={"/dashboard/quiz/:quizID"} element={<LayoutComp component={<StartQuizPage />} />} />
              <Route path={"/dashboard/profile"} element={<LayoutComp component={<ProfilePage />} />} />
              <Route path={"/dashboard/highscore/:quizID"} element={<LayoutComp component={<QuizHighscorePage />} />} />
            </Routes>
            <CustomToast toastMsg={toastMsg} handleClose={() => setToastMsg(null)} />
          </LoginContext.Provider>
        </ToastMsgContext.Provider>
      </BreadCrumbContext.Provider>
    </div >
  );
};
