import { useState, useEffect, Fragment } from "react";
import { ListGroup, Button, Modal, Form } from "react-bootstrap";
import { deleteQuiz, removeQuiz } from "../services/quizapi";
import { QuizResource, CardResource, DeckResource, QuestionResource } from "../utils/resources";
import { getProfileById } from "../services/profileapi";
import { useLoginContext } from "../utils/Context";
import { createQuestionWithCard } from "../services/questionapi";
import { getQuizById } from "../services/quizapi";
import QuizOverviewComp from "./QuizOverviewComp";
import { useNavigate } from "react-router-dom";
import InviteQuizComp from "./InviteQuizComp";

type QuizProps = {
    quiz: QuizResource;
    reload: () => void;
    mode: boolean
};

export default function QuizComp({ quiz, reload, mode }: QuizProps) {
    const { loginInfo } = useLoginContext();
    const navigate = useNavigate();
    const profileId = Number(loginInfo?.profileId);
    const [showModal, setShowModal] = useState(false);
    const [selectedCards, setSelectedCards] = useState<CardResource[]>([]);
    const [decks, setDecks] = useState<DeckResource[] | null>(null); // alle decks des Nutzers
    const [cards, setCards] = useState<CardResource[]>([])           // alle karten des Nutzers
    const [questions, setQuestions] = useState<QuestionResource[] | null>(null);
    const [availableCards, setAvailableCards] = useState<CardResource[] | null>(null);
    const [count, setCount] = useState<number>(0);
    const [showOverviewModal, setShowOverviewModal] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState<number | null>(null);
    const [showInviteModal, setShowInviteModal] = useState(false);

    async function load() {
        try {
            const getProfile = await getProfileById(profileId);
            if (getProfile.decks) {
                setDecks(getProfile.decks);
                if (decks !== null) {
                    let allCards = new Set<CardResource>();

                    decks.forEach(deck => {
                        if (deck.cards !== null) {
                            deck.cards.forEach(card => allCards.add(card));
                        }
                    });
                    const uniqueCards = Array.from(allCards);
                    setCards(uniqueCards);
                }

                if (quiz && quiz.id) {
                    const getQuiz = await getQuizById(quiz.id);
                    const qList = getQuiz.questions;
                    setQuestions(qList);
                }

                if (questions && cards) {
                    const nonMatchingCards = cards.filter((card) =>
                        !questions.some((question) => question.title === card.title)
                    );
                    setAvailableCards(nonMatchingCards);
                } else {
                    setAvailableCards(null);
                }
            }
            // test

        } catch (error) {
            console.error("Error in QuizComp", error);
        }
    }

    useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [profileId, count]);
    useEffect(() => {
        if (questions && cards) {
            const nonMatchingCards = cards.filter((card) => !questions.some((question) => question.title === card.title));
            setAvailableCards(nonMatchingCards);
        }
    }, [questions, cards, quiz]);

    function handleShowModal() {
        setCount(count + 1);
        setShowModal(true);
    }
    const handleCloseModal = () => setShowModal(false);

    async function handleAdd(quizId: number) {
        try {
            // Überprüfen, ob die ausgewählten Karten und Decks bereits im Quiz sind
            if (selectedCards !== null) {
                await Promise.all(
                    selectedCards.map(async (card) => {
                        if (!questions || !questions.some((question: QuestionResource) => question.title === card.title)) {
                            await createQuestionWithCard(card.id!, quizId);
                        }
                    })
                );
                reload();
            }
        } catch (error) {
            console.error("Error adding to quiz", error);
        } finally {
            setSelectedCards([]);
            reload();
            handleCloseModal();
        }
    }

    async function handleDelete(id: number, flag: number) {
        try {
            if (flag === 1) {
                await deleteQuiz(id);
            } else {
                await removeQuiz(id);
            }
            reload()
        } catch (error) {
            console.error("Error deleting quiz", error);
        }
    };

    function clickedOverview(quizId: number) {
        setSelectedQuiz(quizId);
        setShowOverviewModal(true);
    }

    function clickedInvite(quizId: number) {
        setSelectedQuiz(quizId);
        setShowInviteModal(true);
    }

    const addQuestionModal = (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add to Quiz: {quiz.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Select Cards:</Form.Label>
                    {availableCards !== null && availableCards.length !== 0 ? (
                        availableCards.map((card) => (
                            <Form.Check
                                key={card.id}
                                type="checkbox"
                                label={card.title}
                                onChange={() => {
                                    const isSelected = selectedCards.some((selectedCard) => selectedCard.id === card.id);
                                    if (isSelected) {
                                        setSelectedCards(selectedCards.filter((selectedCard) => selectedCard.id !== card.id));
                                    } else {
                                        setSelectedCards([...selectedCards, card]);
                                    }
                                }}
                            />
                        ))
                    ) : (
                        <p>All cards are already added to the quiz.</p>
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary" onClick={() => handleAdd(quiz.id!)} disabled={selectedCards.length === 0}>Add</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <>
            <ListGroup className="quizgroup">
                <ListGroup.Item variant="secondary">{quiz.title}</ListGroup.Item>
                {quiz.creatorId === profileId ?
                    <ListGroup.Item variant="secondary">
                        <div className="d-flex justify-content-between w-100">
                            {mode ?
                                <>
                                    <Button variant="primary" className="me-2 flex-fill" onClick={handleShowModal}>Add</Button>
                                    <Button variant="danger" className="flex-fill" onClick={() => handleDelete(quiz.id!, 1)}>Delete</Button>
                                </>
                                :
                                <Button variant="warning" className="flex-fill" onClick={() => navigate(`/dashboard/quiz/${quiz.id}`)}>Start</Button>
                            }
                        </div>
                    </ListGroup.Item>
                    :
                    <ListGroup.Item variant="secondary">
                        <div className="d-flex justify-content-between w-100">
                            {mode ?
                                <Button variant="danger" className="flex-fill" onClick={() => handleDelete(quiz.id!, 2)}>Delete</Button>
                                :
                                <Button variant="warning" className="flex-fill" onClick={() => navigate(`/dashboard/quiz/${quiz.id}`)}>Start</Button>
                            }
                        </div>
                    </ListGroup.Item>
                }
                <ListGroup.Item variant="secondary">
                    <Button variant='link' onClick={() => clickedOverview(quiz.id!)}>Overview</Button>
                    <Button variant='link' onClick={() => navigate(`/dashboard/highscore/${quiz.id!}`)}>Highscores</Button>
                    {quiz.creatorId === profileId
                        ?
                        <Button variant='link' onClick={() => clickedInvite(quiz.id!)}>Invite Token</Button>
                        :
                        <></>
                    }
                </ListGroup.Item>
            </ListGroup>
            <QuizOverviewComp
                quizId={selectedQuiz}
                show={showOverviewModal}
                onHide={function (): void { setShowOverviewModal(false); load(); }}
            />
            <InviteQuizComp
                quizId={quiz.id!}
                show={showInviteModal}
                onHide={function (): void { setShowInviteModal(false); load(); }}
            />
            {addQuestionModal}
        </>
    );
}
