import React, { useState } from "react";
import { DeckResource } from "../utils/resources";
import {Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomProgressBar from "./customElements/customProgressBar";
import CreateDeckComp from "./CreateDeckComp";
import EditDeckComp from "./EditDeckComp";

interface DeckProps {
    deck: DeckResource;
    flag?: string;
    updateBoard: any;
    disabled?: boolean;
    showBadge?: boolean;
}

function DeckComp({ deck, flag, updateBoard, disabled, showBadge }: DeckProps) {
    const [showCreateDeckModal, setShowCreateDeckModal] = useState(false);
    const [showDeckDetailsModal, setShowDeckDetailsModal] = useState(false);
    const processed = deck.processedCards!;
    const unprocessed = deck.cards.length - processed;

    const toggleCreateDeckModal = (isOpen: boolean) => {
        setShowCreateDeckModal(isOpen);
    };

    const toggleDeckDetailsModal = (isOpen: boolean) => {
        setShowDeckDetailsModal(isOpen);
    };

    return (
        <>
            <OverlayTrigger placement={'top'} overlay={flag ? <Tooltip> Create Deck </Tooltip> : <></>} >
                <Button
                    className={`deckbtn ${disabled && showBadge ? "selected wiggle" : ""}`}
                    variant={disabled ? "outline-secondary" : "secondary"}
                    onClick={() => !disabled && (flag ? toggleCreateDeckModal(true) : toggleDeckDetailsModal(true))}
                >
                    {flag ? (
                        <>
                            <i className="bi bi-plus-circle me-1 fs-4"></i>
                            <CustomProgressBar processed={0} unprocessed={0} />
                        </>
                    ) : (
                        <>
                            <div className="prop" style={{ color: disabled ? "gray" : undefined }}>{deck.title}</div>
                            <div className="prop" style={{ color: disabled ? "gray" : undefined }}>Cards: {deck.cards?.length}</div>
                            <CustomProgressBar processed={processed} unprocessed={unprocessed} />
                        </>
                    )}
                    {showBadge && disabled && (
                        <div style={{ borderRadius: '50%', width: "2em", height: "2em", backgroundColor: "#198754", color: "white"}} className="position-absolute top-0 start-100 translate-middle">
                            <i className="bi bi-check2-circle fs-5"></i>
                        </div>
                    )}
                </Button>
            </OverlayTrigger>
            <EditDeckComp onShow={showDeckDetailsModal} onClose={() => toggleDeckDetailsModal(false)} updateBoard={updateBoard} deck={deck} processed={processed} unprocessed={unprocessed} />
            <CreateDeckComp handleShow={showCreateDeckModal} handleClose={() => toggleCreateDeckModal(false)} updateBoard={updateBoard} />
        </>
    )
}

export default DeckComp;