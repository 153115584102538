import { DeckResource } from "../utils/resources";
import { retrieveJWT } from "../utils/jwt";
import ValidationFailedError from "../utils/validationFailedError";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

function headersApi() {
  const headers: any = {
    Accept: "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

export async function getDeckById(deckId: number): Promise<DeckResource> {
  const url = `${HOST}/api/deck?id=${deckId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function createDeck(deck: DeckResource): Promise<DeckResource> {
  const url = `${HOST}/api/deck/create/`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(deck),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  const data = response.json();
  return data;
}

export async function updateDeck(deck: DeckResource): Promise<boolean> {
  const url = `${HOST}/api/deck/update?id=${deck.id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(deck),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}

export async function deleteDeck(deckId: number): Promise<boolean> {
  const url = `${HOST}/api/deck/delete?id=${deckId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers()
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}

export async function deleteManyDecks(deckIds: number[]): Promise<boolean> {
  const url = `${HOST}/api/deck/deletemany`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify(deckIds),
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}


export async function generateDeck(pdfFile: File, howMany: number): Promise<boolean> {
  const url = `${HOST}/api/ai/generate/deck/`;
    const formData = new FormData();
    formData.append("pdfFile", pdfFile);
    formData.append("numberInput", howMany.toString());
    const response = await fetch(url, {
        method: "POST",
        headers: headersApi(),
        body: formData,
    });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}