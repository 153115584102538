import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DeckComp from "../components/DeckComp";
import { DeckResource } from "../utils/resources";
import { getLearnProgress } from "../services/statisticsapi";
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { deleteManyDecks } from "../services/deckapi";

function DashboardPage({ update }: any) {
  const [decks, setDecks] = useState<DeckResource[] | null>(null)
  const mockDeck: DeckResource = { title: "title", profileId: -1, cards: [] }
  const { setBreadCrumb } = useBreadCrumbContext()
  const [editMode, setEditMode] = useState(false);
  const [selectedDeckIds, setSelectedDeckIds] = useState<number[]>([]);

  async function loadBoard() {
    try {
      const deckRes = await getLearnProgress();
      setDecks(deckRes)
    } catch (err) {
      setDecks(null)
    }
  }

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setSelectedDeckIds([]);
  };

  const handleDeckClick = (deckId: number) => {
    if (editMode) {
      setSelectedDeckIds((prevIds) =>
        prevIds.includes(deckId)
          ? prevIds.filter((id) => id !== deckId)
          : [...prevIds, deckId]
      );
    }
  };

  async function handleDelete() {
    if (await deleteManyDecks(selectedDeckIds)) {
      toggleEditMode();
      loadBoard();
    }
  }

  useEffect(() => {
    update();
    loadBoard();
    setBreadCrumb(generateBreadcrumbs())
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div>
        <Row>
          <div className="d-flex justify-content-start">
            <div className="d-flex align-items-center">
              <h1 className="ml-5">Deck Overview</h1>
            </div>
            {decks?.length !== 0 &&
              <div className="m-1">
                <Button variant={editMode ? "success" : "danger"} onClick={toggleEditMode}>
                  <i className="bi bi-pencil-square fs-5"></i>
                </Button>
              </div>
            }
            {editMode &&
              <div className="m-1">
                <Button variant="danger" onClick={handleDelete} disabled={selectedDeckIds.length === 0}>
                  <i className="bi bi-trash fs-5"></i>
                </Button>
              </div>
            }
          </div>
        </Row>
        <Row>
          {decks !== null && (
            decks.map((deck: DeckResource) => (
              <Col
                key={deck.id}
                className="d-flex justify-content-center"
                onClick={() => handleDeckClick(deck.id!)}
              >
                <DeckComp deck={deck} updateBoard={loadBoard} disabled={editMode} showBadge={editMode && selectedDeckIds.includes(deck.id!)} />
              </Col>
            ))
          )}
          {editMode !== true &&
            <Col className="d-flex justify-content-center">
              <DeckComp deck={mockDeck} updateBoard={loadBoard} flag="1" />
            </Col>
          }
        </Row>
      </div>
    </>
  );
}
export default DashboardPage;
