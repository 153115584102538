import { CardRating, CardResource, CardType } from "../utils/resources";
import { retrieveJWT } from "../utils/jwt";
import ValidationFailedError from "../utils/validationFailedError";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

export async function getCardById(cardId: number): Promise<CardResource> {
  const url = `${HOST}/api/card?id=${cardId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function createCard(card: CardResource): Promise<boolean> {
  const url = `${HOST}/api/card/create/`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(card),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}

export async function createManyCards(cards: CardResource[]): Promise<boolean> {
  const url = `${HOST}/api/card/createmany/`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(cards),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}

export async function updateCard(card: CardResource): Promise<boolean> {
  const url = `${HOST}/api/card/update?id=${card.id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(card),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    console.log(errorResponse)
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}

export async function deleteCard(cardId: number): Promise<boolean> {
  const url = `${HOST}/api/card/delete?id=${cardId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers()
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}

export async function deleteManyCards(cardIds: number[]): Promise<boolean> {
  const url = `${HOST}/api/card/deletemany`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify(cardIds),
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}

export function cardTypeToString(cardType: CardType): string {
  switch (cardType) {
    case CardType.MultipleChoice:
      return "Multiple Choice";
    case CardType.SingleChoice:
      return "Single Choice";
    case CardType.Standard:
      return "Standard";
    case CardType.Error:
      return "Error";
    default:
      return "Error";
  }
}

export function stringToCardType(cardTypeString: string): CardType | undefined {
  switch (cardTypeString) {
    case "Multiple Choice":
      return CardType.MultipleChoice;
    case "Single Choice":
      return CardType.SingleChoice;
    case "Image":
      return CardType.Standard;
    case "Error":
      return CardType.Error;
    default:
      return CardType.Error;
  }
}

export function cardRatingToString(cardRating: number ): string {
  switch (cardRating) {
    case CardRating.NotLearned:
      return 'NotLearned';
    case CardRating.Easy:
      return 'Easy';
    case CardRating.Good:
      return 'Good';
    case CardRating.Hard:
      return 'Hard';
    case CardRating.Error: 
      return 'Error';
    default:
      return 'Error';
  }
}

export function stringToCardRating(cardRating: string ): CardRating | undefined{
  switch (cardRating) {
    case 'NotLearned':
      return CardRating.NotLearned;
    case 'Easy':
      return CardRating.Easy;
    case 'Good':
      return CardRating.Good;
    case 'Hard':
      return CardRating.Hard;
    case 'Error': 
      return CardRating.Error;
    default:
      return CardRating.Error;
  }
}