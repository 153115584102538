import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getAllRecordsByQuizId } from "../services/quizrecordapi";
import { useParams } from "react-router-dom";
import { QuizRecord } from "../utils/resources";

function QuizHighscorePage() {
    const quizID = useParams().quizID!
    const [highscores, setHighscores] = useState<any[]>([]);

    async function load() {
        try {
            const data = await getAllRecordsByQuizId(Number(quizID));
            setHighscores(sortData(data));
        } catch (error) {
            console.error("Error fetching highscore data");
        }
    }

    function sortData(data: QuizRecord[]): QuizRecord[] {
        const sortedData = data.sort((a, b) => {
            if (b.score !== a.score) {
                return b.score - a.score;
            }
            if (a.finishedAt && b.finishedAt && a.startedAt && b.startedAt) {
                const timeDifferenceA = new Date(a.finishedAt).getTime() - new Date(a.startedAt).getTime();
                const timeDifferenceB = new Date(b.finishedAt).getTime() - new Date(b.startedAt).getTime();
                return timeDifferenceA - timeDifferenceB;
              }
            return 0;
        });
        return sortedData;
    }

    function formatTimeDifference(startedAt: string | number | Date, finishedAt: string | number | Date) {
        const startTime = new Date(startedAt).getTime();
        const endTime = new Date(finishedAt).getTime();
        if(endTime === 0 || startTime === 0){
            return "-"
        }
        const timeDifferenceInMilliseconds = endTime - startTime;
        const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
        const seconds = Math.floor((timeDifferenceInMilliseconds % (1000 * 60)) / 1000);
        return `${minutes} min ${seconds} sec`;
      }
      

    useEffect(() => {
        load();
        // eslint-disable-next-line
    }, []);

    if (highscores.length === 0) {
        return (
            <div className="text-center">
                <h1>Sorry, No Highscores made yet!</h1>
            </div>
        )
    } else {
        return (
            <div className="pageView">
                <Table className="cardTable">
                    <thead>
                        <tr className="align-items-center">
                            <th className="card-question-th">#</th>
                            <th className="card-answer-th">
                                <div className="justify-content-between align-middle">
                                    Name
                                </div>
                            </th>
                            <th className="card-tag-th">Score</th>
                            <th className="card-tag-th">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {highscores !== null &&
                            highscores.map((highscore, index) => (
                                <tr key={highscore.id}>
                                    <td className="card-td">{index+1}</td>
                                    <td className="card-td"><>
                                        <img width="50px" className="mx-1" src={"../../" + highscore.profileData.picture} alt="profile_picture" />
                                        {highscore.profileData.name}
                                    </></td>
                                    <td className="card-td">{highscore.score}</td>
                                    <td className="card-td">{formatTimeDifference(highscore.startedAt, highscore.finishedAt)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default QuizHighscorePage;