import { retrieveJWT } from "../utils/jwt";
import { QuizResource } from "../utils/resources";
import ValidationFailedError from "../utils/validationFailedError";
const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

export async function getQuizById(quizId: number): Promise<QuizResource> {
  const url = `${HOST}/api/quiz?id=${quizId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    const errorData = await response.json();
    console.error("Error connecting to Host:", errorData);
    throw new Error("Error bei getQuizById");
  }
  const data = await response.json();
  return data;
}

export async function createQuiz(quiz: QuizResource): Promise<QuizResource> {
  const url = `${HOST}/api/quiz/create/`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(quiz),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  const data = response.json();
  return data;
}

export async function deleteQuiz(quizId: number): Promise<boolean> {
  const url = `${HOST}/api/quiz/delete?id=${quizId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers()
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}


export async function getQuizToken(quizId: number): Promise<String> {
  const url = `${HOST}/api/quiz/getToken?id=${quizId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error bei getQuizToken");
  }
  const data = await response.json();
  return data;
}

export async function addUserWithToken(profileId: number, token: string) {
  const url = `${HOST}/api/quiz/adduserwithtoken?profileId=${profileId}&token=${token}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: headers()
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  } 

const responseBody = await response.text(); 
// Check if the response body is not empty before parsing as JSON
const data = responseBody ? JSON.parse(responseBody) : null;
  return data;
}

export async function removeQuiz(quizId: number): Promise<boolean> {
    const url = `${HOST}/api/quiz/remove?id=${quizId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers()
    });
    if (!response.ok) {
      throw new Error("Error connecting to Host");
    }
    return response.ok;
  }