import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { login } from "../services/accountapi";
import { isTokenExpired, retrieveJWT, storeJWT } from "../utils/jwt";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import ValidationFailedError from "../utils/validationFailedError";
import logo from "../assets/img/logo_gruen_horizontal.svg";

function PageLogin() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const refEmail = useRef<HTMLInputElement>(null);
  const refPassword = useRef<HTMLInputElement>(null);

  const switchPage = async () => {
    navigate("/signup");
  };

  useEffect(() => {
    const storedToken = retrieveJWT();
    if (storedToken && !isTokenExpired(storedToken)) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setValidated(true);
    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      e.stopPropagation(); return;
    }
    refEmail.current?.setCustomValidity('');
    refPassword.current?.setCustomValidity('');
    setEmailValidationMessage('')
    setPasswordValidationMessage('')
    try {
      const jwt = await login(refEmail.current!.value, refPassword.current!.value);
      if (jwt != null) {
        setValidated(false);
        setErrorMessage("");
        storeJWT(jwt);
        navigate("/dashboard");
      }
    } catch (err: any) {
      if (err instanceof ValidationFailedError) {
        const errorFields = Object.keys(err.errors);
        errorFields.forEach((field) => {
          const errorMessages = err.errors[field];
          switch (field) {
            case "AccountMail":
              refEmail.current?.setCustomValidity(errorMessages[0]);
              setEmailValidationMessage(errorMessages[0])
              break;
            case "Password":
              if (errorMessages.length === 1) {
                refPassword.current?.setCustomValidity(errorMessages[0]);
                setPasswordValidationMessage("The Password field is not valid password")
              } else {
                refPassword.current?.setCustomValidity(errorMessages[0]);
                setPasswordValidationMessage(errorMessages[0])
              }
              break;
            default:
              setErrorMessage("Something went wrong");
              break;
          }
        });
      } else {
        setErrorMessage("Something went wrong");
      }
    }
  };

  return (
    <Container className="Authentication-form-container">
      <Form className="Authentication-form" validated={validated}>
        <div className="Authentication-form-content">
          <img src={logo} alt="" />
          <h3 className="Authentication-form-title">Login</h3>
          <div className="switchLabel">
            Not registered yet?{" "}
            <span className="link-success" onClick={switchPage}>
              Sign Up
            </span>
          </div>
          <Form.Group className="mt-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control placeholder="name@example.com" type="email" id={"refEmail"} onChange={() => setValidated(false)} ref={refEmail} />
            <Form.Control.Feedback type="invalid">{emailValidationMessage}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control placeholder="1234" type="password" id={"refPassword"} onChange={() => setValidated(false)} ref={refPassword} />
            <Form.Control.Feedback type="invalid">{passwordValidationMessage}</Form.Control.Feedback>
          </Form.Group>
          <small style={{ color: 'red' }}> {errorMessage}</small>
          <div className="d-grid gap-2 mt-3">
            <Button variant="success" type="submit" onClick={handleLogin}>
              Login
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
}

export default PageLogin;
