/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { QuizResource } from '../utils/resources';
import { getProfileById } from '../services/profileapi';
import { useLoginContext } from '../utils/Context';
import CreateQuizComp from '../components/CreateQuizComp';
import QuizComp from '../components/QuizComp';
import AddUserWithTokenComp from '../components/AddUserWIthTokenComp';
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";

const QuizPage: React.FC = () => {
    const { loginInfo } = useLoginContext();
    const profileId = Number(loginInfo?.profileId);
    const [quizzes, setQuizzes] = useState<QuizResource[] | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const { setBreadCrumb } = useBreadCrumbContext()
    const [editMode, setEditMode] = useState(false);

    async function load() {
        try {
            const getProfile = await getProfileById(profileId);
            if (getProfile.quizzes) {
                setQuizzes(getProfile.quizzes);
            }
        } catch (error) {
            console.error("Error loading profile", error);
            setQuizzes(null);
        }
    }

    useEffect(() => {
        load();
        setBreadCrumb(generateBreadcrumbs())
    }, [profileId]);

    useEffect(() => {
        if (quizzes?.length === 0) {
            toggleEditMode();
        }
    }, [quizzes]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
      };

    return (
        <>
            <div>
                <div className="d-flex align-items-center">
                    <h1>Quiz Overview</h1>
                    <Button variant="primary" className='m-1' onClick={() => setShowCreateModal(true)}>
                        Create Quiz
                    </Button>
                    <Button className='m-1' variant="primary" onClick={() => setShowAddModal(true)}>
                        Add Quiz
                    </Button>
                    {quizzes?.length !== 0 &&
                        <div className="m-1">
                            <Button variant={editMode ? "success" : "danger"} onClick={toggleEditMode}>
                                <i className="bi bi-pencil-square fs-6"></i>
                            </Button>
                        </div>
                    }
                </div>
                <Fragment>
                    <CreateQuizComp show={showCreateModal} onHide={function (): void {
                        setShowCreateModal(false); load();
                    }}></CreateQuizComp>
                </Fragment>

                <Fragment>
                    <AddUserWithTokenComp show={showAddModal} onHide={function (): void {
                        setShowAddModal(false); load();
                    }}></AddUserWithTokenComp>
                </Fragment>
                <h4 className='mt-1'>Available Quizzes</h4>
                {quizzes && (
                    <div className="mt-3">
                        <Row className="d-flex justify-content-center">
                            {quizzes.map((quiz) => (
                                <Col key={quiz.id} className="d-flex justify-content-center m-3">
                                    <QuizComp quiz={quiz} reload={load} mode={editMode}></QuizComp>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            </div>
        </>
    );
};

export default QuizPage;
