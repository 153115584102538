import { retrieveJWT } from "../utils/jwt";
import { CardProgressResource, CardStatisticsResource, DeckResource, DeckStatisticsResource, ProfileStatisticsResource } from "../utils/resources";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

// ProfileStatistics
export async function getProfileStatisticsById(ProfileId: number): Promise<ProfileStatisticsResource> {
  const url = `${HOST}/api/profilestatistics?id=${ProfileId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function updateProfileStatistics(profilestatistics: ProfileStatisticsResource) {
  const url = `${HOST}/api/profilestatistics/update?id=${profilestatistics.id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(profilestatistics),
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}

export async function getTimeSpendTotal(profilestatisticsId: number): Promise<number> {
  const url = `${HOST}/api/profilestatistics/timespantotal/?id=${profilestatisticsId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

// DeckStatistics
export async function getDeckStatisticsByDeckId(deckId: number): Promise<DeckStatisticsResource> {
  const url = `${HOST}/api/deckstatistics?id=${deckId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function getDeckAmount(): Promise<number> {
  const url = `${HOST}/api/deckstatistics/deckamount`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function getLearnProgress(): Promise<DeckResource[]> {
  const url = `${HOST}/api/deckstatistics/learnprogress`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function updateDeckStatistics(deckId: number) {
  const url = `${HOST}/api/deckstatistics/update?id=${deckId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}

// CardStatistics
export async function getCardStatisticsByCardId(cardId: number): Promise<CardStatisticsResource> {
  const url = `${HOST}/api/cardstatistics?id=${cardId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function getCardProgressTotal(): Promise<CardProgressResource[]> {
  const url = `${HOST}/api/cardstatistics/cardprogresstotal`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function getCardProgressDeck(deckId: number): Promise<CardProgressResource> {
  const url = `${HOST}/api/cardstatistics/cardprogressdeck?id=${deckId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function updateCardStatisticsByCardId(cardStatistic: CardStatisticsResource) {
  const url = `${HOST}/api/cardstatistics/update?id=${cardStatistic.cardId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(cardStatistic),
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}
