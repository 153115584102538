import { Card, Form, InputGroup } from "react-bootstrap";
import { getCorrectAnswers, getIncorrectAnswers, getTrimmedAnswers, randomizeAnswers } from "../utils/cardanswerutils";
import { CardResource, CardType, QuestionResource } from "../utils/resources";
import { useEffect, useState } from "react";


function CardAnswersComp(prop: { card: CardResource | QuestionResource, callback: any }) {
    const [answers, setAnswers] = useState<string[]>([]);
    const [selectedAnswers, setSelectedAnswers] = useState<string>("");

    const handleCheckChange = (event: { target: { name: string; checked: boolean; value: string }; }) => {
        let newSelectedAnswers = "";
        if (prop.card.type === CardType.SingleChoice) {
            if (selectedAnswers.indexOf("&false;" + event.target.value) > -1) {
                newSelectedAnswers = selectedAnswers.replaceAll("&true;", "&false;");
                newSelectedAnswers = newSelectedAnswers.replace("&false;" + event.target.value, "&true;" + event.target.value);

            }
        } else {
            if (selectedAnswers.indexOf("&false;" + event.target.value) > -1) {
                newSelectedAnswers = selectedAnswers.replace("&false;" + event.target.value, "&true;" + event.target.value);
            } else if (selectedAnswers.indexOf("&true;" + event.target.value) > -1) {
                newSelectedAnswers = selectedAnswers.replace("&true;" + event.target.value, "&false;" + event.target.value);
            }
        }

        setSelectedAnswers(newSelectedAnswers)
        if (prop.callback != null) {
            prop.callback(newSelectedAnswers)
        }
    }

    useEffect(() => {
        if (answers.length === 0) {
            let randomAnswers = getCorrectAnswers(prop.card.content);
            randomAnswers = [...randomAnswers, ...getIncorrectAnswers(prop.card.content)];
            randomAnswers = randomizeAnswers(randomAnswers);
            setAnswers(randomAnswers);

            let result = "";

            for (let i of randomAnswers) {
                result += ("&false;" + i + " &OR; ");
            }

            setSelectedAnswers(result)
            if (prop.callback != null) {
                prop.callback(result)
            }
        }
    }, [selectedAnswers]);

    const getContent = () => {
        if (prop.card.type === CardType.MultipleChoice) {
            let forms: JSX.Element[] = [];
            for (let i in answers) {
                const nameProp = "answeroption" + i;
                const keyForm = nameProp + "form";
                forms.push(
                    <div key={keyForm}>
                        <InputGroup>
                            <InputGroup.Checkbox onChange={handleCheckChange} value={answers[i]} />
                            <Form.Control type="text" value={answers[i]} readOnly />
                        </InputGroup>
                    </div>
                )
            }
            return forms;
        } else if (prop.card.type === CardType.SingleChoice) {
            let forms: JSX.Element[] = [];
            for (let i in answers) {
                const nameProp = "option" + i;
                const keyForm = nameProp + "form";
                forms.push(
                    <div key={keyForm}>
                        <InputGroup>
                            <InputGroup.Radio name="radiocheck" onChange={handleCheckChange} value={answers[i]} />
                            <Form.Control type="text" value={answers[i]} readOnly />
                        </InputGroup>
                    </div>
                )
            }
            return forms;
        } else {
            return <Card.Text>Content: {getTrimmedAnswers(prop.card.content)}</Card.Text>
        }
    }

    return (
        <>
            {getContent()}
        </>
    );
}

export default CardAnswersComp;