import React from "react";
import SidebarComp from "../components/SidebarComp";
import { useBreadCrumbContext } from "../utils/Context";
import Breadcrumbs from "./customElements/customBreadcrumbs";

function LayoutComp({ component }: { component: any }) {
  const { breadCrumb } = useBreadCrumbContext()

  return (
    <div className="d-flex" style={{ width: '100vw' }}>
      <SidebarComp />
      <div className="content" style={{ flex: 1 }}>
        <Breadcrumbs items={breadCrumb!} />
        {component}
      </div>
    </div>
  );
}

export default LayoutComp;
