import { AccountResource, LoginResource } from "../utils/resources";
import ValidationFailedError from "../utils/validationFailedError";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return headers;
}

export async function login(accountmail: string, password: string): Promise<string> {
  const url = `${HOST}/api/account/login`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ accountmail, password }),
  });
  if (response.ok) {
    const loginResource: LoginResource = await response.json();
    return loginResource.token;
  }
  if (response.status === 401) {
    throw new Error("Invalid credentials");
  }
  const errorResponse = await response.json();
  const { errors, status, title, traceId, type } = errorResponse;
  if (errors) {
    throw new ValidationFailedError(status, title, traceId, type, errors);
  } else {
    throw new Error("Unexpected error");
  }
}

export async function register(name: string, account: AccountResource, file: File): Promise<boolean> {
  let picture = null;
  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${HOST}/api/account/uploadprofilepicture`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { Accept: "application/json" },
        body: formData,
      });
      const promise = response.json();
      picture = await promise;
    } catch (error) {
      throw new Error('Error uploading image');
    }
  }
  const url = `${HOST}/api/account/register/`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ name, picture, account }),
  });
  
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}




