import React, { useState, useEffect } from 'react';
import { getQuizToken } from '../services/quizapi';
import { Button, Modal } from 'react-bootstrap';

type AddUserTokenProps = {
  quizId: number | null
  show: boolean
  onHide: () => void
}


function InviteQuizComp({ quizId, show, onHide }: AddUserTokenProps) {
  const [invitationToken, setInvitationToken] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(invitationToken!);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy token: ', err);
    }
  };

  async function load() {
    try {
      if (quizId != null) {
        const token = await getQuizToken(quizId);
        setInvitationToken(String(token));
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, [quizId]);

  const shareInvitationLink = () => {
    if (invitationToken) {
      return (
        <div className='d-flex justify-content-between m-1'>
          <div className='text-center mt-2'>{invitationToken}</div>
          <Button variant='warning' onClick={handleCopyClick} disabled={isCopied} className="mb-1">
            {isCopied ? <i className="bi bi-clipboard-fill fs-5"></i> : <i className="bi bi-clipboard fs-5"></i>}
          </Button>
        </div>
      );
    } else {
      return (
        <p>
          Invitation Token is null or undefined
        </p>
      )
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => { setIsCopied(false); onHide(); }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Your Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {shareInvitationLink()}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InviteQuizComp;