import { Button, Card, Modal, Table } from "react-bootstrap";
import { CardResource, CardType } from "../utils/resources";
import { useState } from "react";
import { cardTypeToString } from "../services/cardapi";
import { getAllAnswers, removeBoolean } from "../utils/cardanswerutils";
import { useNavigate } from "react-router-dom";

interface CardProps {
    deckID: string;
    card: CardResource;
    exportCard: (card: CardResource) => void;
    showBadge: boolean;
    editMode: boolean;
}

function CardComp({ deckID, card, exportCard, showBadge, editMode }: CardProps) {
    const navigate = useNavigate();
    const [showOverviewModal, setShowOverviewModal] = useState(false);

    function Answers(): string[] {
        const allAnswers = getAllAnswers(removeBoolean(card.content));
        if (allAnswers.length > 0) {
            return allAnswers.slice(0, allAnswers.length - 1);
        }
        return allAnswers;
    }

    function handleEdit(cardID: number) {
        navigate(`/dashboard/deck/${deckID}/editcard/${cardID}`);
    }

    const overviewModal = (
        <>
            <Modal show={showOverviewModal} onHide={() => setShowOverviewModal(!showOverviewModal)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Answer(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        {card.type === CardType.Standard ? (
                            <tbody>
                                <td>
                                    {card.content}
                                </td>
                            </tbody>
                        ) : (
                            <tbody>
                                {Answers().map((answer, index) => (
                                    <tr key={index}>
                                        <td>{answer}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )
                        }
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );

    return (
        <>
            <Card className={`container-fluid mx-3 d-flex justify-content-start ${showBadge && editMode && !showOverviewModal ? "selected wiggle" : ""}`}>
                <Card.Body>
                    <Card.Title>
                        <div className="d-flex justify-content-between">
                            {cardTypeToString(card.type)}
                            {!editMode && (
                                <Button className="mx-1" variant="dark" onClick={() => exportCard(card)} >
                                    <i className="bi bi-download"></i>
                                </Button>
                            )}
                            {editMode && (
                                <Button variant="primary" className="mx-1" onClick={() => handleEdit(card.id!)}>
                                    <i className="bi bi-pencil fs-6"></i>
                                </Button>
                            )}
                        </div>
                    </Card.Title>
                    <Card.Text>{card.title}</Card.Text>
                    <Button variant='link' onClick={() => setShowOverviewModal(!showOverviewModal)}>show Answer(s)</Button>
                </Card.Body>
                {showBadge && editMode && !showOverviewModal && (
                    <div
                        style={{ borderRadius: '50%', width: "2em", height: "2em", backgroundColor: "#198754", color: "white" }}
                        className="position-absolute top-0 start-100 translate-middle"
                    >
                        <i className="bi bi-check2-circle fs-4 m-1"></i>
                    </div>
                )}
            </Card >
            {overviewModal}
        </>
    );

}


export default CardComp;


