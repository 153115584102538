import { FormEvent, useState, useEffect } from "react";
import { createManyCards } from "../services/cardapi";
import { CardResource, CardType, } from "../utils/resources";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ValidationFailedError from "../utils/validationFailedError";
import { useBreadCrumbContext } from "../utils/Context";
import CreateCardComp from "../components/CreateCardComp";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { removeBoolean } from "../utils/cardanswerutils";

function CreateCardPage() {
    const deckID = useParams().deckID!
    const navigate = useNavigate();
    const [titleValidationMessages, setTitleValidationMessages] = useState<Record<number, string>>({});
    const [contentValidationMessages, setContentValidationMessages] = useState<Record<number, string>>({});
    const [checkValidationMessages, setCheckValidationMessages] = useState<Record<number, string>>({});
    const [inputValidationMessages, setInputValidationMessages] = useState<Record<number, string>>({});
    const [errorMessage, setErrorMessage] = useState("");
    const { setBreadCrumb } = useBreadCrumbContext()
    const [cards, setCards] = useState<CardResource[]>([{ title: "", content: "", deckId: Number(deckID), type: CardType.Standard }]);

    const addCard = () => {
        setCards((prevCards) => [
            ...prevCards,
            {
                title: "",
                content: "",
                deckId: Number(deckID),
                type: CardType.Standard,
            },
        ]);
    };

    const removeCard = (index: number) => {
        setCards((prevCards) => {
            const newCards = [...prevCards];
            newCards.splice(index, 1);
            return newCards;
        });
    };

    async function createCards(e: FormEvent) {
        e.preventDefault();
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        setTitleValidationMessages({});
        setContentValidationMessages({});
        setCheckValidationMessages({});
        setInputValidationMessages({});
        setErrorMessage("");
        try {
            validateInputs(cards)
            validateChecks(cards)
            if (await createManyCards(cards)) {
                console.log(cards)
                navigate(-1);
            }
        } catch (err: any) {
            if (err instanceof ValidationFailedError) {
                const errorFields = Object.keys(err.errors);
                const titleErrors: Record<number, string> = {};
                const contentErrors: Record<number, string> = {};
                const checkErrors: Record<number, string> = {};
                const inputErrors: Record<number, string> = {};
                errorFields.forEach((field) => {
                    const errorMessage = err.errors[field];
                    const fieldParts = field.split('.');
                    const fieldName = fieldParts[fieldParts.length - 1];
                    const fieldIndex = parseInt(fieldParts[0].replace('[', '').replace(']', ''), 10);
                    switch (fieldName) {
                        case "Title":
                            titleErrors[fieldIndex] = errorMessage;
                            break;
                        case "Content":
                            contentErrors[fieldIndex] = errorMessage;
                            break;
                        case "Check":
                            checkErrors[fieldIndex] = (errorMessage);
                            break;
                        case "Input":
                            inputErrors[fieldIndex] = (errorMessage);
                            break;
                        default:
                            setErrorMessage("Something went wrong");
                            break;
                    }
                });
                setTitleValidationMessages(titleErrors ? titleErrors : []);
                setContentValidationMessages(contentErrors ? contentErrors : []);
                setCheckValidationMessages(checkErrors ? checkErrors : []);
                setInputValidationMessages(inputErrors ? inputErrors : []);
            } else {
                setErrorMessage("Something went wrong");
            }
        }
    }

    function validateChecks(cards: CardResource[]): void {
        const errors: Record<string, string[]> = {};
        cards.forEach((card, index) => {
            if (!card.content.includes("&true;") && card.type !== CardType.Standard && card.content !== "") {
                errors["[" + index + "].Check"] = ["At least one checkbox for a correct answer must be checked"];
            }
        });
        if (Object.keys(errors).length > 0) {
            throw new ValidationFailedError(400, "Validation failed", "", "ValidationFailed", errors);
        }
    }

    function validateInputs(cards: CardResource[]): void {
        const errors: Record<string, string[]> = {};
        cards.forEach((card, index) => {
            if (card.type !== CardType.Standard)
                try {
                    validateContentFormat(card.content, index);
                } catch (error) {
                    if (error instanceof ValidationFailedError) {
                        Object.entries(error.errors).forEach(([field, messages]) => {
                            errors[field] = messages;
                        });
                    }
                }
        });
        if (Object.keys(errors).length > 0) {
            throw new ValidationFailedError(400, "Validation failed", "", "ValidationFailed", errors);
        }
    }

    function validateContentFormat(content: string, index: number): void {
        const contentWithoutTrue = removeBoolean(content);
        if (contentWithoutTrue.includes('&OR;')) {
            const parts = contentWithoutTrue.split('&OR;').slice(0, -1);
            const temp =  contentWithoutTrue.split('&OR;').slice(0, -1);
            const invalidParts = parts.filter(part => !part.trim());
            if (invalidParts.length > 0 || temp.length < 2) {
                throw new ValidationFailedError(400, "Validation failed", "", "ValidationFailed", {
                    [`[${index}].Input`]: ["Two answeroptions are required"]
                });
            }
        }
    }

    const handleCardChange = (index: number, updatedCard: CardResource) => {
        setCards((prevCards) => {
            const updatedCards = [...prevCards];
            updatedCards[index] = updatedCard;
            return updatedCards;
        });

        setTitleValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[index];
            return newMessages;
        });

        setContentValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[index];
            return newMessages;
        });

        setCheckValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[index];
            return newMessages;
        });

        setInputValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[index];
            return newMessages;
        });

        setErrorMessage("");
    };

    useEffect(() => {
        setBreadCrumb(generateBreadcrumbs())
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {cards.map((card, index) => (
                <CreateCardComp
                    key={index}
                    index={index}
                    deckID={deckID}
                    titleMessages={titleValidationMessages}
                    contentMessages={contentValidationMessages}
                    checkMessages={checkValidationMessages}
                    inputMessages={inputValidationMessages}
                    onCardChange={(newCard) => handleCardChange(index, newCard)}
                />
            ))}
            <div className="d-flex justify-content-center">
                <Button className="add-card mt-3 m-1" style={{ borderRadius: '50%' }} variant="outline-primary" onClick={addCard}>
                    <i className="bi bi-plus-circle-fill fs-4"></i>
                </Button>
                {cards.length > 1 &&
                    <Button className="add-card mt-3 m-1" style={{ borderRadius: '50%' }} variant="outline-danger" onClick={() => removeCard(cards.length - 1)}>
                        <i className="bi bi-dash-circle-fill fs-4"></i>
                    </Button>
                }
            </div>
            <small style={{ color: 'red' }}> {errorMessage}</small>
            <div className="d-flex justify-content-start">
                <Button className="card-createBtn m-3" variant="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button className="card-createBtn m-3" type="submit" variant="success" onClick={createCards}>
                    Create
                </Button>
            </div>
        </>
    );
}

export default CreateCardPage;
