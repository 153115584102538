import React from "react";
import logo from "../../assets/img/logo.png";

function CustomPreLoader() {
    return (
        <div className="preloader-container">
            <div className="preloader-animation">
            </div>
            <div className="preloader-logo-container">
                <img src={logo} alt="Logo" className="preloader-logo" />
            </div>
        </div>
    );
}

export default CustomPreLoader;
