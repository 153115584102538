import React from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomProgressBar from './customElements/customProgressBar';
import { exportDeck } from '../services/importexportapi';
import { deleteDeck } from '../services/deckapi';
import { useNavigate } from 'react-router-dom';
import { DeckResource } from '../utils/resources';
import { CustomDelete } from './customElements/customDelete';
import { useDialogToggler } from '../hooks/useDialogToggler';
import { updateDeckStatistics } from '../services/statisticsapi';

interface DeckProps {
    updateBoard: any;
    onShow: boolean;
    onClose: () => void;
    deck: DeckResource;
    processed: number;
    unprocessed: number;
}

function EditDeckComp({ onShow, onClose, updateBoard, deck, processed, unprocessed }: DeckProps) {
    const { showDialog, handleShow, handleClose } = useDialogToggler();
    const navigate = useNavigate();

    async function handleDelete() {
        handleClose();
        await deleteDeck(deck.id!);
        updateBoard();
    }

    function handleEdit() {
        navigate(`/dashboard/deck/${deck.id}`)
    }

    async function handleExport() {
        const deckfile = await exportDeck(deck);
        const blob = new Blob([deckfile], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${deck.title}-Deck.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    async function handleStart() {
        await updateDeckStatistics(deck.id!)
        navigate(`/dashboard/learn/${deck.id}`);
    }

    return (
        <>
            <Modal className="deckmodal" centered show={onShow} onHide={onClose}>
                <Modal.Header closeButton>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip> Download and Share your Deck </Tooltip>}>
                        <Button className="btn" variant="success" onClick={handleExport}>
                            Export
                        </Button>
                    </OverlayTrigger>
                    <Modal.Title style={{ marginLeft: "10px" }}>Deck: {deck.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button className="deckbtn" id="editDeckBtn" variant="secondary" onClick={handleEdit}>
                        <>
                            <div className="prop">{deck.title}</div>
                            <div className="prop">Cards: {deck.cards?.length}</div>
                            <CustomProgressBar processed={processed} unprocessed={unprocessed} flag={1} />
                        </>
                    </Button>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        <Button className="btn" variant="danger" onClick={handleShow}>
                            Delete
                        </Button>
                    </div>
                    {deck.cards.length !== 0 &&
                        <div>
                            <Button className="btn" variant="warning" onClick={handleStart} disabled={deck.cards.length === 0}>
                                <i className="bi bi-play-fill"></i>
                                Start
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
            <CustomDelete showDialog={showDialog} handleClose={handleClose} handleDelete={handleDelete} />
        </>
    );
};

export default EditDeckComp;
