import React from "react";
import { Badge, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem {
  path: string;
  label: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb className="mt-2">
      {items.map((item, index) => (
        <Breadcrumb.Item key={index} active={index === items.length - 1}>
          {index === items.length - 1 ? (
            <Badge bg="secondary">
              <span className="d-flex justify-content-center" >{item.label}</span>
            </Badge>
          ) : (
            <Badge bg="primary" className="custom-badge" onClick={() => navigate(item.path)}>
              <div className="d-flex justify-content-center">{item.label}</div>
            </Badge>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
