import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface ProgressBarProps {
  processed: number;
  unprocessed: number;
  flag?: number;
}

export default function CustomProgressBar({ processed, unprocessed, flag }: ProgressBarProps) {
  const total = processed + unprocessed;
  const processedPercentage = Math.round((processed / total) * 100);
  const unprocessedPercentage = Math.round((unprocessed / total) * 100);
  const isTotalZero = total === 0;

  if (isTotalZero) {
    return (
      <ProgressBar className='progressbar'>
        <ProgressBar variant="dark" now={100} label={flag ? '0%' : null} />
      </ProgressBar>
    );
  }

  return (
    <ProgressBar className='progressbar'>
      <ProgressBar className='success' variant="success" now={processedPercentage} label={flag ? `${processedPercentage}%` : null} />
      <ProgressBar className='unprocessed' variant="danger" now={unprocessedPercentage} label={flag ? `${unprocessedPercentage}%` : null} />
    </ProgressBar>
  );

}
