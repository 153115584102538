import { retrieveJWT } from "../utils/jwt";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

export async function createQuestionsWithDeck(deckId: number, quizId: number) {
  const url = `${HOST}/api/question/createwithdeck?deckId=${deckId}&quizId=${quizId}`;
  const response = await fetch(url, {
    method: "POST",
    headers: headers()
  });
  if (!response.ok) {
    throw new Error("Error bei createQuestionsWithDeck");
  }
  const data = await response.json();
  return data;
}

export async function createQuestionWithCard(cardId: number, quizId: number) {
  const url = `${HOST}/api/question/createwithCard?cardId=${cardId}&quizId=${quizId}`;
  const response = await fetch(url, {
      method: "POST",
      headers: headers()  
    });
  if (!response.ok) {
      console.error("Fehler bei createQuestionWithCard:", response.status, response.statusText);
      throw new Error("Fehler bei createQuestionWithCard");
  }
  const data = await response.json();
  return data;
}

export async function getById(quizId: number) {
  const url = `${HOST}/api/question/getById?id=${quizId}`;
  const response = await fetch(url, {
    method: "GET", headers: headers(),
  });
  if (!response.ok) {
    throw new Error("Error bei getById. HTTP-Statuscode: " + response.status);
  }
  const data = await response.json();
  return data;
}


export async function checkAnswer(answer: string, question: any) {
  const url = `${HOST}/api/question/checkanswer?answer=${answer}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(question),
  });

  if (!response.ok) {
    throw new Error("Error in checkAnswer. HTTP-Statuscode: " + response.status);
  }

  const result = await response.json();
  return result;
}