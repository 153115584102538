import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CardType, QuestionResource, QuizRecord, QuizResource } from "../utils/resources";
import { getQuizById } from "../services/quizapi";
import { Button, Card, Container, Fade } from "react-bootstrap";
import { getRecordByQuizId, updateRecord } from "../services/quizrecordapi";
import { useLoginContext } from "../utils/Context";
import CardAnswersComp from "../components/CardAnswersComp";
import CardAnswersClickDummyComp from "../components/CardAnswersClickDummyComp";
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { cardTypeToString } from "../services/cardapi";
import { checkAnswer } from "../services/questionapi";


export default function StartQuizPage() {
    const { loginInfo } = useLoginContext();
    const profileId = Number(loginInfo?.profileId);
    const { quizID } = useParams();
    const navigate = useNavigate();
    const [quiz, setQuiz] = useState<QuizResource>();
    const [questionAnswer, setQuestionAnswer] = useState<string>("");
    const [questions, setQuestions] = useState<QuestionResource[]>();
    const [record, setRecord] = useState<QuizRecord | null>(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [startedAt, setStartedAt] = useState<Date>();
    const [showQuestion, setShowQuestion] = useState(true);
    const { setBreadCrumb } = useBreadCrumbContext()
    const [totalPoints, setTotalPoints] = useState(0);
    const [currentPoints, setCurrentPoints] = useState(0)

    async function load() {
        try {
            if (quizID != null) {
                const quiz = await getQuizById(Number(quizID));
                setQuiz(quiz);
                if (quiz.questions) {
                    setQuestions(quiz.questions);
                }
                if (startedAt === undefined) {
                    // update Quiz Records ohne Datum (wird im Backend mit Current_Timestamp gesetzt)
                    const currentTime = Date.now();
                    const currentRecord = await getRecordByQuizId(Number(quizID));
                    if (currentRecord != null)
                        await updateRecord({ id: currentRecord.id, profileId: profileId, quizId: Number(quizID), score: 0 });
                    setStartedAt(new Date(currentTime));
                }
                if (quiz.records) {
                    quiz.records.forEach((r) => {
                        if (r.profileId === profileId) {
                            setRecord(r);
                        }
                    })
                }
            }
        } catch (error) {
            console.error("Error loading quiz", error);
        }
    }

    async function calculatePoints() {
        if (questions![currentQuestionIndex].type !== CardType.Standard) {
            const result = await checkAnswer(questionAnswer.replaceAll("&", "%26"), questions![currentQuestionIndex]);
            if (typeof result === "number") {
                setTotalPoints((prevTotalPoints) => prevTotalPoints + result);
                setCurrentPoints(result);
            }
        }
    }

    function handleSetQuestionAnswer(dummyCompAnswer: string) {
        setQuestionAnswer(dummyCompAnswer)
    }

    function handleNextQuestion() {
        // Check if there are more questions
        if (currentQuestionIndex < (questions?.length || 0) - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setShowAnswer(false)
        }
        setCurrentPoints(0);
    };

    function doStuff() {
        setShowQuestion(!showQuestion);
    }

    function handleCancel() {
        // score wird nicht bewertet und kein update im record
        navigate(-1);
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        // Hier wird der Score berechnet
        if (startedAt !== undefined) {
            await updateRecord({ id: record!.id, profileId: profileId, quizId: quiz!.id!, score: totalPoints });
        }
        if (quizID !== undefined) {
            navigate(`/dashboard/highscore/${quizID}`);
        } else {
            navigate(-1);
            console.log("Error: QuizID is undefined");
        }

    } // test

    useEffect(() => {
        load();
        setBreadCrumb(generateBreadcrumbs())
        // eslint-disable-next-line
    }, [quizID, questionAnswer]);

    if (quizID === undefined) {
        return null;
    }
    if (questions === undefined) {
        return <Container>Your Quiz is empty...</Container>;
    }

    return (
        <>
            <Container>
                <Card>
                    <Card.Header>
                        Question {currentQuestionIndex + 1}/{questions.length}
                    </Card.Header>
                    <div>
                        <h1 className="text-center">{questions[currentQuestionIndex].title}</h1>
                        {questions[currentQuestionIndex].type === CardType.Standard ?
                            showQuestion && currentQuestionIndex !== 1 && (
                                <span></span>
                            ) :
                            showQuestion && (
                                <>
                                    <div className='m-1'>
                                        {cardTypeToString(questions[currentQuestionIndex].type)} Question
                                        {questions[currentQuestionIndex].type === 0 && (
                                            <span> (several correct answers possible)</span>
                                        )}
                                        {questions[currentQuestionIndex].type === 1 && (
                                            <span> (only one correct answer possible)</span>
                                        )}
                                    </div>
                                    <CardAnswersClickDummyComp card={questions[currentQuestionIndex]} callback={handleSetQuestionAnswer} />
                                </>
                            )}
                    </div>
                    <Fade in={showAnswer}>
                        <div className="m-1 align-items-center">
                            <Card border="success" className='card'>
                                <Card.Body className='cardBody'>
                                    <div className="cardContent">
                                        {questions[currentQuestionIndex].type === CardType.Standard ?
                                            showAnswer && (
                                                <Card.Text className='text-center'>{questions[currentQuestionIndex].content}</Card.Text>
                                            ) :
                                            showAnswer && (
                                                <CardAnswersComp card={questions[currentQuestionIndex]} disabled={true} callback={false} validationMsg={''} />
                                            )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Fade>
                    <div className="d-flex justify-content-center">{showAnswer && (
                        <p>Points for this Question: {currentPoints}</p>
                    )}
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>Total Points: {totalPoints}</p>
                    </div>
                    <Card.Footer style={{ marginTop: '10px' }}>
                        <div className="d-flex justify-content-center">
                            {!showAnswer && (
                                <>
                                    <Button variant="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={() => { setShowAnswer(true); doStuff(); calculatePoints(); }}>
                                        Show Answer
                                    </Button>
                                </>
                            )}
                            {showAnswer && currentQuestionIndex < (questions?.length || 0) - 1 && (
                                <Button variant="primary" onClick={() => { handleNextQuestion(); doStuff(); }}>
                                    Next Question
                                </Button>
                            )}
                            {showAnswer && currentQuestionIndex === (questions?.length || 0) - 1 && (
                                <Button variant="success" onClick={handleSubmit}>
                                    Finish Quiz
                                </Button>
                            )}
                        </div>
                    </Card.Footer>
                </Card>
            </Container >
        </>
    );
}