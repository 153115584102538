import React from "react";
import { Modal, Button } from "react-bootstrap";

interface DeleteProps {
  showDialog: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

export function CustomDelete({ showDialog, handleClose, handleDelete }: DeleteProps) {
  return (
    <Modal className="deleteModal" backdrop="static" show={showDialog} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure to delete this??</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button style={{ marginRight: "10px" }} variant="dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  );
}
