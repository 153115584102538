import { retrieveJWT } from "../utils/jwt";
import { ProfileResource } from "../utils/resources";
import ValidationFailedError from "../utils/validationFailedError";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwt = retrieveJWT();
  if (jwt !== null) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

export async function getProfileById(profileId: number): Promise<ProfileResource> {
  const url = `${HOST}/api/profile?id=${profileId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  const data = response.json();
  return data;
}

export async function updateProfile(profile: ProfileResource): Promise<boolean> {
  const url = `${HOST}/api/profile/update?id=${profile.id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(profile),
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errors, status, title, traceId, type } = errorResponse;
    if (errors) {
      throw new ValidationFailedError(status, title, traceId, type, errors);
    } else {
      throw new Error("Unexpected error");
    }
  }
  return response.ok;
}

export async function deleteProfile(profileId: number): Promise<boolean> {
  const url = `${HOST}/api/profile/delete?id=${profileId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers()
  });
  if (!response.ok) {
    throw new Error("Error connecting to Host");
  }
  return response.ok;
}