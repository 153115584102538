import { Card } from "react-bootstrap";
import { ProfileResource, ProfileStatisticsResource } from "../utils/resources";

function ProfileComp(Props: { profileStatistics: ProfileStatisticsResource | null, profile: ProfileResource | null }) {
    const stats = Props.profileStatistics;
    const profile = Props.profile;


    return (
        <>
            {/* <h1>Profile</h1>
        <div className="h-100 d-flex justify-content-between align-items-center">
            <div>
            <img src="https://via.placeholder.com/150" alt="profile" />
            </div>
            <div>
            <h2>Profile Name</h2>
            <p>joined since Month Year</p>
            </div>
            <div>
            <p>Hours spend</p>
            <p>Decks</p>
            <p>Streak</p>
            </div>
            <div>
            <p>Friends</p>
            <Button type="submit" variant="primary" style={{ marginLeft: "10px" }}>
                    Add Friends
            </Button>
            </div>
            

        </div> */}

            {/* <div className="div-block-2">
                <div className="div-block">
                    <div className="profileblock statblock">
                        <img src={profile?.picture} alt="profile picture" />
                        <div>
                            <h1>{profile?.name}</h1>
                            <p>joined {stats?.joinedDate}</p>
                        </div>
                    </div>
                    <div className="profileblock statblock">
                        <div>
                            <p>Time spend</p>
                            <p className="statp">{stats?.score}</p>
                        </div>
                        <div>
                            <p>Streak</p>
                            <p className="statp">{stats?.streak}</p>
                        </div>
                        <div>
                            <p>Decks</p>
                            <p className="statp">{profile?.decks?.length}</p>
                        </div>
                        <div>
                            <p>Friends</p>
                            <p className="statp">3</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div id="w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcc-ce66f49c" className="w-layout-cell statcell"> */}
            <Card>
            <div id="w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcc-ce66f49c" className="w-layout-cell statcell">
                <div className="profilestat">
                    <img width="200px" src={"../"+ profile?.picture} alt="profile_picture" />
                </div>
                <div className="profilestat">
                    <h1>{profile?.name}</h1>
                    <p>joined {stats?.joinedDate}</p>
                </div>
                {/* <div>
                    <div className="profilestat">
                        <p>Time spend</p>
                        <h2>{stats?.score}</h2>
                    </div>
                </div> */}
                {/* <div className="profilestat">
                    <p>Streak</p>
                    <h2>{stats?.streak}</h2>
                </div> */}
                <div className="profilestat">
                    <p>Decks</p>
                    <h2>{profile?.decks?.length}</h2>
                </div>
                {/* <div className="profilestat">
                    <p>Friends</p>
                    <h2 >0</h2>
                </div> */}
                </div>
            </Card>
            {/* </div> */}
        </>
    )
}

export default ProfileComp;