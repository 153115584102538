export interface LoginResource {
  token: string;
  token_type: "Bearer";
}

export interface AccountResource {
  id?: number;
  profileId?: number;
  accountmail: string;
  password: string;
}

export interface ProfileResource {
  id?: number;
  name: string;
  picture?: string;
  account: AccountResource;
  profileimage?: ProfileImageResource;
  decks?: DeckResource[];
  quizzes?: QuizResource[]
}

export interface ProfileImageResource {
  imageHeight: number;
  imageWidth: number;
  imageSize: number;
  imageFilePath: string;
}

export interface ProfileStatisticsResource {
  id?: number;
  profileId: number;
  score: number;
  streak: number;
  joinedDate?: string;
}

export interface DeckResource {
  id?: number;
  title: string;
  profileId: number;
  cards: CardResource[];
  processedCards?: number;
}

export interface DeckStatisticsResource {
  id?: number;
  deckId: number;
  lastActive?: Date;
}

export interface CardResource {
  id?: number;
  deckId: number;
  type: CardType;
  title: string;
  content: string;
}

export interface CardStatisticsResource {
  id?: number;
  cardId: number;
  rating: number;
  lastActive?: Date;
  points: number;
}

export interface CardProgressResource {
  [key: string]: number;
}

export interface QuizResource {
  id?: number;
  title: string;
  creatorId: number;
  token: string;
  profileIds: number[];
  questions: QuestionResource[];
  records: QuizRecord[];
}

export interface QuestionResource {
  id?: number;
  quizId: number;
  type: CardType;
  title: string;
  content: string;
}

export interface QuizRecord {
  id?: number;
  profileId: number;
  quizId: number;
  score: number;
  startedAt?: Date;
  finishedAt?: Date;
}

export enum CardRating {
  NotLearned,
  Easy,
  Good,
  Hard,
  Error,
}

export enum CardType {
  MultipleChoice,
  SingleChoice,
  Standard,
  Error,
}

export const cardTypeOptions = [
  { value: CardType.Standard, label: "Standard" },
  { value: CardType.MultipleChoice, label: "Multiple Choice" },
  { value: CardType.SingleChoice, label: "Single Choice" }
  ,
  //{ value: CardType.Error, label: "Error" },
];
