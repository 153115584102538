import React, { useEffect, useState } from "react";
import ProfileComp from "../components/ProfileComp";
import StatisticsAllCardsProgressComp from "../components/StatisticsAllCardsProgressComp";
import StatisticsDecksProgressComp from "../components/StatisticsDecksProgressComp";
import { getCardProgressDeck, getCardProgressTotal, getLearnProgress, getProfileStatisticsById } from "../services/statisticsapi";
import { useLoginContext } from "../utils/Context";
import { DeckResource, ProfileResource, ProfileStatisticsResource } from "../utils/resources";
import { getProfileById } from "../services/profileapi";
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { Card, Col, Container, Row } from "react-bootstrap";

function ProfilePage() {
  const { loginInfo } = useLoginContext()
  const profileId = loginInfo?.profileId
  const [profileStatistics, setProfileStatistics] = useState<ProfileStatisticsResource | null>(null)
  const [profile, setProfile] = useState<ProfileResource | null>(null)
  const [cardProgressTotal, setCardProgressTotal] = useState<any | null>(null)//TODO: change any to CardProgressTotal
  const [decks, setDecks] = useState<DeckResource[] | null>(null)
  const [cardProgressDeck, setCardProgressDeck] = useState<{ [key: string]: any } | null>(null)
  const { setBreadCrumb } = useBreadCrumbContext()

  async function load() {
    try {
      const profileData = await getProfileById(Number(profileId))
      setProfile(profileData)
    } catch (err) {
      setProfile(null)
      console.log(err);
    }
    try {
      const profileStats = await getProfileStatisticsById(Number(profileId))
      setProfileStatistics(profileStats)
    } catch (err) {
      setProfileStatistics(null)
      console.log(err);
    }
    try {
      const progressTotal = await getCardProgressTotal();
      setCardProgressTotal(progressTotal)
    } catch (err) {
      setCardProgressTotal(null)
      console.log(err);
    }
    try {
      const deckRes = await getLearnProgress();
      if (decks === null) {
        setDecks(deckRes);
      }
    } catch (err) {
      setDecks(null);
      console.log(err);
    }
    try {
      if (decks) {
        let progressForEveryDeck: { [key: string]: any } = {};
        await Promise.all(decks.map(async (deck) => {
          if (deck.id) {
            const cardProgressDeck = await getCardProgressDeck(deck.id);
            progressForEveryDeck[deck.title] = cardProgressDeck;
          }
        }));
        setCardProgressDeck(progressForEveryDeck);
      }
    } catch (err) {
      setCardProgressDeck(null);
    }
  }

  useEffect(() => {
    load()
    setBreadCrumb(generateBreadcrumbs())
    // eslint-disable-next-line
  }, [decks]);

  return (
    <>
      <div>
        <Container>
          <Row>
            <Col>
              <ProfileComp profile={profile} profileStatistics={profileStatistics} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <div>
                <Card className="m-1">
                  <StatisticsAllCardsProgressComp cardProgresstotal={cardProgressTotal} />
                </Card>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div>
                <Card className="m-1">
                  <StatisticsDecksProgressComp cardProgressDeck={cardProgressDeck} />
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default ProfilePage;
