import { BreadcrumbItem } from "./Context";

export const generateBreadcrumbs = (): BreadcrumbItem[] => {
  const currentPath = window.location.pathname;
  const paths = currentPath.split("/").filter((path) => path !== "");
  let breadcrumbs: BreadcrumbItem[] = [];
  let currentFullPath = "";
  for (let i = 0; i < paths.length; i++) {
    currentFullPath += `/${paths[i]}`;
    if (paths[i] === "deck" || paths[i] === "editcard" || paths[i] === "learn") {
      const pathSegment = paths[i];
      let label = capitalizeFirstLetter(pathSegment);
      if (i + 1 < paths.length && paths[i + 1] !== "" && isNumeric(paths[i + 1])) {
        i += 1;
        currentFullPath += `/${paths[i]}`;
      }
      breadcrumbs.push({ path: currentFullPath, label });
    } else {
      breadcrumbs.push({ path: currentFullPath, label: capitalizeFirstLetter(paths[i]) });
    }
  }
  return breadcrumbs;
};

const isNumeric = (value: string) => {
  return /^\d+$/.test(value);
};

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};