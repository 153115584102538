import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardResource, DeckResource } from "../utils/resources";
import { getDeckById, updateDeck } from "../services/deckapi";
import { Button, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { cardTypeToString, deleteManyCards } from "../services/cardapi";
import { useDialogToggler } from "../hooks/useDialogToggler";
import { readFile } from "../utils/filereader";
import { importCard } from "../services/importexportapi";
import ValidationFailedError from "../utils/validationFailedError";
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { updateDeckStatistics } from "../services/statisticsapi";
import { exportCard } from "../services/importexportapi";
import { AnswerBadgeComp } from "../components/AnswerBadgeComp";
import CardComp from "../components/CardComp";

function DeckPage() {
    const deckID = useParams().deckID!
    const navigate = useNavigate();
    let refTitle = useRef<HTMLInputElement>(null);
    const refFileInput = useRef<HTMLInputElement>(null);
    const { showDialog, handleShow, handleClose } = useDialogToggler();
    const { setBreadCrumb } = useBreadCrumbContext()
    const [cards, setCards] = useState<CardResource[] | null>(null)
    const [deck, setDeck] = useState<DeckResource | null>(null)
    const [showAnswers, setShowAnswers] = useState(false);
    const [showDeckModal, setShowDeckModal] = useState(false);
    const [titleValidationMessage, setTitleValidationMessage] = useState("");
    const [importValidationMessage, setImportValidationMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

    async function load() {
        try {
            const deckRes = await getDeckById(Number(deckID))
            setDeck(deckRes)
            setCards(deckRes.cards)
        } catch (err) {
            setDeck(null)
            setCards(null)
        }
    }

    useEffect(() => {
        load()
        setBreadCrumb(generateBreadcrumbs())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    function handleCreate() {
        navigate(`/dashboard/deck/${deck!.id}/createcard`)
    }

    const resetFileInput = () => {
        if (refFileInput.current) {
            refFileInput.current.value = "";
        }
    };

    async function handleImport(e: FormEvent) {
        e.preventDefault();
        setValidated2(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refFileInput.current?.setCustomValidity('')
        setImportValidationMessage('')
        try {
            const fileInput = refFileInput.current;
            const file = fileInput!.files![0];
            const cardContent = await readFile(file);
            const card = JSON.parse(cardContent);
            if (await importCard(card, Number(deckID))) {
                setValidated2(false);
                resetFileInput();
                load();
                handleClose();
            }
        } catch (error: any) {
            if (error instanceof TypeError) {
                refFileInput.current?.setCustomValidity("No file was chosen");
                setImportValidationMessage("No file was chosen");
            } else if (error instanceof SyntaxError) {
                refFileInput.current?.setCustomValidity("Error importing card. The file is probably not a Json file.");
                setImportValidationMessage("Error importing card. The file is probably not a Json file.")
            } else {
                setImportValidationMessage("Something went wrong");
            }
        }
    }

    function handleEdit(cardID: number) {
        navigate(`/dashboard/deck/${deckID}/editcard/${cardID}`);
    }

    const handleCardSelect = (event: React.ChangeEvent<HTMLInputElement>, cardId: number) => {
        if (event.target.checked) {
            setSelectedCardIds((prevIds) => [...prevIds, cardId]);
        } else {
            setSelectedCardIds((prevIds) => prevIds.filter((id) => id !== cardId));
        }
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setSelectedCardIds([]);
    };

    async function handleDelete() {
        if (await deleteManyCards(selectedCardIds)) {
            toggleEditMode();
            load();
        }
    }

    const handleCardClick = (cardId: number) => {
        if (editMode) {
            setSelectedCardIds((prevIds) =>
                prevIds.includes(cardId)
                    ? prevIds.filter((id) => id !== cardId)
                    : [...prevIds, cardId]
            );
        }
    };

    async function handleDeckEdit(e: FormEvent) {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refTitle.current?.setCustomValidity('')
        setTitleValidationMessage('')
        const deckRes = {
            id: deck?.id,
            title: refTitle.current?.value,
            profileId: deck?.profileId,
            cards: deck?.cards,
            processedCards: deck?.processedCards
        } as DeckResource;
        try {
            if (await updateDeck(deckRes)) {
                setValidated(false);
                load();
                setShowDeckModal(!showDeckModal)
                setEditMode(false)
            }
        } catch (err: any) {
            if (err instanceof ValidationFailedError) {
                const errorFields = Object.keys(err.errors);
                errorFields.forEach((field) => {
                    const errorMessages = err.errors[field];
                    switch (field) {
                        case "Title":
                            refTitle.current?.setCustomValidity(errorMessages[0]);
                            setTitleValidationMessage(errorMessages[0])
                            break;
                        default:
                            setErrorMessage("Invalid input");
                            break;
                    }
                });
            } else {
                setErrorMessage("Invalid input");
            }
        }
    }

    async function handleExport(card: CardResource) {
        const cardfile = await exportCard(card);
        const blob = new Blob([cardfile], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${card.title}-Card.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    async function handleStart() {
        await updateDeckStatistics(Number(deckID))
        navigate(`/dashboard/learn/${deckID}`);
    }

    const importModal = (
        <Modal centered show={showDialog} onHide={() => { handleClose(); resetFileInput(); setValidated2(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Import Card
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
                <Form validated={validated2}>
                    <Form.Group>
                        <InputGroup>
                            <Form.Control type="file" ref={refFileInput} id="fileInput" />
                            <InputGroup.Text>
                                <i className="bi bi-filetype-json fs-5"></i>
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{importValidationMessage}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="primary" className="my-2 btn" onClick={handleImport}>
                    Import
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const editModal = (
        <>
            <Modal centered show={showDeckModal} onHide={() => setShowDeckModal(!showDeckModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Deck Title
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column align-items-center">
                    <Form validated={validated}>
                        <Form.Group className="form-inline">
                            <Row className="align-items-center">
                                <Form.Label column xs="auto">
                                    Title:
                                </Form.Label>
                                <Col xs="auto">
                                    <Form.Control type="text" placeholder="Enter Title" id="title" ref={refTitle} defaultValue={deck?.title} />
                                    <Form.Control.Feedback type="invalid">{titleValidationMessage}</Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <small style={{ color: 'red' }}> {errorMessage}</small>
                </Modal.Body><Modal.Footer className="d-flex justify-content-end">
                    <div>
                        <Button variant="primary" className="my-2 btn" onClick={handleDeckEdit}>
                            Save
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );

    const table = (
        <Table className="cardTable">
            <thead>
                <tr className="align-items-center">
                    <th className="card-question-th">Question</th>
                    <th className="card-answer-th">
                        <div className="justify-content-between align-middle">
                            Answer
                            <OverlayTrigger placement={'top'} overlay={<Tooltip> {showAnswers ? 'Hide Answers' : 'Show Answers'} </Tooltip>}>
                                <Button variant="link" onClick={() => setShowAnswers(!showAnswers)}>
                                    <i className={`bi bi-eye${showAnswers ? '-slash' : ''}`}></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </th>
                    <th className="card-tag-th">Typ</th>
                    {!editMode &&
                        <th className="card-export-th">Export</th>
                    }
                    {editMode &&
                        <>
                            <th className="card-delete-th">Delete</th>
                            <th className="card-edit-th">Edit</th>
                        </>
                    }
                </tr>
            </thead>
            <tbody>
                {cards !== null &&
                    cards.map((card: CardResource) => (
                        <tr key={card.id}>

                            <td className="card-td">{card.title}</td>
                            <td className="card-td">{showAnswers ? AnswerBadgeComp(card.content, card.type) : '*********'}</td>
                            <td className="card-td">{cardTypeToString(card.type)}</td>
                            {!editMode && (
                                <td className="card-export-td">
                                    <Button className="m-1" variant="dark" onClick={() => handleExport(card!)}>
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            )}
                            {editMode && (
                                <>
                                    <td className="card-delete-td">
                                        <Form.Check
                                            className="d-flex justify-content-center align-items-center m-2"
                                            id="card-delete-check"
                                            type="checkbox"
                                            onChange={(event) => handleCardSelect(event, card.id!)}
                                            checked={selectedCardIds.includes(card.id!)}
                                        />
                                    </td>
                                    <td className="card-edit-td">
                                        <Button variant="primary" onClick={() => handleEdit(card.id!)}>
                                            <i className="bi bi-pencil"></i>
                                        </Button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    );

    const cardList = (
        <Row className="align-items-center justify-content-center">
            {cards !== null &&
                cards.map((card: CardResource) => (
                    <div key={card.id} onClick={() => handleCardClick(card.id!)} className="m-2 d-flex justify-content-start">
                        <CardComp card={card} showBadge={editMode && selectedCardIds.includes(card.id!)} editMode={editMode} deckID={deckID} exportCard={handleExport} />
                    </div>
                ))
            }
        </Row>
    );

    return (
        <div className="pageView">
            {deck !== null && (
                <>
                    <div className="d-flex justify-content-start align-items-center">
                        <div>
                            <Button className="btn-title" variant={editMode ? "link" : ""} onClick={() => editMode && setShowDeckModal(!showDeckModal)}>
                                {editMode ? (
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip> Edit Deck Title </Tooltip>}>
                                        <div className="deck-title">{deck.title}</div>
                                    </OverlayTrigger>
                                ) : (
                                    <div className="deck-title">{deck.title}</div>
                                )}
                            </Button>
                        </div>
                        {cards?.length !== 0 &&
                            <div className="m-1">
                                <Button variant={editMode ? "success" : "danger"} onClick={toggleEditMode}>
                                    <i className="bi bi-pencil-square fs-5"></i>
                                </Button>
                            </div>
                        }
                        {editMode &&
                            <div className="m-1">
                                <Button variant="danger" onClick={handleDelete} disabled={selectedCardIds.length === 0}>
                                    <i className="bi bi-trash fs-5"></i>
                                </Button>
                            </div>
                        }
                    </div>
                    {isMobile ? (
                        cardList
                    ) : (
                        table
                    )}
                    <Row className="align-items-center justify-content-start">
                        {!editMode &&
                            <>
                                <Button className="btn-card" variant="success" onClick={handleCreate}>
                                    Create Card
                                </Button>
                                <Button className="btn-card" variant="primary" onClick={handleShow}>
                                    Import Card
                                </Button>
                            </>
                        }
                        {cards?.length !== 0 && !editMode &&
                            <Button className="btn-card" variant="warning" onClick={handleStart} disabled={cards?.length === 0}>
                                <i className="bi bi-play-fill"></i>
                                Start
                            </Button>
                        }
                    </Row>
                </>
            )}
            {importModal}
            {editModal}
        </div>
    );
}

export default DeckPage;
