import { useState } from 'react';
import { CardType } from '../utils/resources';

export const useCardTypeChange = () => {
  const [selectedCardType, setSelectedCardType] = useState<CardType>(CardType.Standard);

  const handleCardTypeChange = (type: CardType) => {
    setSelectedCardType(type);
  };

  return {
    selectedCardType,
    handleCardTypeChange,
  };
};

export const useCardTypeChangeBtnGrp = () => {
  const [selectedCardType, setSelectedCardType] = useState<CardType>(CardType.Standard);

  const handleCardTypeChange = (type: CardType) => {
    setSelectedCardType(type);
  };

  return {
    selectedCardType,
    handleCardTypeChange,
  };
};
