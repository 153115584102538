import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { ToastMsg } from "../../utils/Context";

interface ToastProps {
    toastMsg: ToastMsg | null;
    handleClose: () => void;
}

const CustomToast = ({ toastMsg, handleClose }: ToastProps) => {
    return (
        <ToastContainer className="p-3" position={'top-end'} style={{ zIndex: 1 }} >
            <Toast bg="success" onClose={handleClose} show={!!toastMsg} delay={3000} autohide>
                <Toast.Header className="justify-content-between">
                    <strong>{toastMsg?.header}</strong>
                </Toast.Header>
                <Toast.Body className='text-white'>{toastMsg?.msg}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default CustomToast;
