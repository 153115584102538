import { Form } from "react-bootstrap";

function CreateCardStandardAnswer(prop: { callback: any, currentAnswerContent: string, validationMsg: string }) {

    return (
        <Form.Group className="full-width">
            <Form.Label>Answer:</Form.Label>
            <Form.Control onInput={(e) => prop.callback(e)} value={prop.currentAnswerContent} className="textfield" as="textarea" placeholder="Enter Answer" id={"content"} isInvalid={!!prop.validationMsg} />
            <Form.Control.Feedback type="invalid">{prop.validationMsg}</Form.Control.Feedback>
        </Form.Group>
    );
}

export default CreateCardStandardAnswer;