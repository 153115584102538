import { useState } from "react";
import { addUserWithToken } from "../services/quizapi";
import { useLoginContext } from "../utils/Context";
import { Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ValidationFailedError from "../utils/validationFailedError";


type AddUserWithTokenProps = {
  show: boolean
  onHide: () => void
}

export default function AddUserWithTokenComp({ show, onHide }: AddUserWithTokenProps) {
  const { loginInfo } = useLoginContext();
  const profileId = Number(loginInfo?.profileId);
  const [token, setToken] = useState<string>("")
  const [validationMessage, setValidationMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setToken(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      e.stopPropagation(); return;
    }
    setValidationMessage("")
    try {
      await addUserWithToken(profileId, token);
      onHide();
    } catch (err: any) {
      if (err instanceof ValidationFailedError) {
        const errorFields = Object.keys(err.errors);
        errorFields.forEach((field) => {
          const errorMessages = err.errors[field];
          switch (field) {
            case "token":
              setValidationMessage(errorMessages[0])
              break;
            default:
              setValidationMessage("Something went wrong");
              break;
          }
        });
      } else {
        setValidationMessage("Something went wrong");
      }
    }
  };


  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton >
          <Modal.Title>Add Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Enter Token below</Form.Label>
              <Form.Control type="text" name="token" value={token} onChange={handleChange} isInvalid={!!validationMessage} />
              <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" variant="primary" >Add new Quiz</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};