import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


function StatisticsAllCardsProgressComp(Props: { cardProgresstotal: any | null }) {

  if (Props.cardProgresstotal === null) {
    return (
      <div>
        Loading...
      </div>
    )
  }
  const cardProgresstotal = Props.cardProgresstotal;
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'All Cards Progress',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const data = {
    labels: ['not learned', 'Hard', 'Good', 'Easy'],
    datasets: [
      {
        label: '# of Cards',
        data: [cardProgresstotal[0],
        cardProgresstotal[3],
        cardProgresstotal[2],
        cardProgresstotal[1]],

        backgroundColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)'

        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className='chart-size'>
      <Doughnut options={options} data={data} />
    </div>
  );
}


export default StatisticsAllCardsProgressComp;