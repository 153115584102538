const JWT_STORAGE_KEY = "jwt";

export function retrieveJWT(): string | null {
  return localStorage.getItem(JWT_STORAGE_KEY);
}

export function storeJWT(jwt: string): void {
  localStorage.setItem(JWT_STORAGE_KEY, jwt);
}

export function removeJWT(): void {
  localStorage.removeItem(JWT_STORAGE_KEY);
}


export function isTokenExpired(jwt: string | null): boolean {
  if (!jwt) {
    return true;
  }
  try {
    const decodedToken = JSON.parse(atob(jwt.split(".")[1]));
    const exp = decodedToken['exp']
    if (decodedToken && exp) {
      const expirationTimeInMillis = exp * 1000;
      return expirationTimeInMillis < Date.now();
    }
    return true;
  } catch (error: any) {
    console.error("Error decoding or parsing JWT:", error.message);
    return true;
  }
}

export function getLoginInfoFromJWT(jwt: string | null): { profileId: string } | null {
  if (jwt === null) {
    return null;
  }
  try {
    const claimSet = JSON.parse(atob(jwt.split(".")[1]));
    const profileId = claimSet['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    if (profileId === null) {
      return null;
    }
    return { profileId };
  } catch (error: any) {
    console.error("Error decoding JWT:", error.message);
    return null;
  }
}