import React, { FormEvent, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useLoginContext, useToastMsgContext } from "../utils/Context";
import { CardResource, DeckResource } from "../utils/resources";
import { createDeck, generateDeck } from "../services/deckapi";
import { importDeck } from "../services/importexportapi";
import { readFile } from "../utils/filereader";
import ValidationFailedError from "../utils/validationFailedError";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "./customElements/customSelect";

interface DeckProps {
    updateBoard: any;
    handleShow: boolean;
    handleClose: () => void;
}

function CreateDeckComp({ handleShow, handleClose, updateBoard }: DeckProps) {
    const [mode, setMode] = useState("create");
    const { loginInfo } = useLoginContext()
    const { setToastMsg } = useToastMsgContext();
    const profileId = loginInfo?.profileId
    const emptyArray: CardResource[] = [];
    let refTitle = useRef<HTMLInputElement>(null);
    const refFileInput = useRef<HTMLInputElement>(null);
    const refPDFInput = useRef<HTMLInputElement>(null);
    const [numberInput, setNumberInput] = useState<number>(1);
    const [titleValidationMessage, setTitleValidationMessage] = useState("");
    const [importValidationMessage, setImportValidationMessage] = useState("");
    const [generateValidationMessage, setGenerateValidationMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [validated3, setValidated3] = useState(false);
    const navigate = useNavigate();

    async function handleCreate(e: FormEvent) {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refTitle.current?.setCustomValidity('')
        setTitleValidationMessage('')
        const deckRes = {
            profileId: Number(profileId),
            title: refTitle.current?.value,
            cards: emptyArray
        } as DeckResource;
        try {
            const response: DeckResource = await createDeck(deckRes);
            if (response) {
                setValidated(false);
                handleClose();
                setMode("create")
                navigate(`/dashboard/deck/${response.id}`)
            }
            // createDeckInBackground(deckRes)
            // setValidated(false);
            // handleClose();
            // setMode("create")
        } catch (err: any) {
            if (err instanceof ValidationFailedError) {
                const errorFields = Object.keys(err.errors);
                errorFields.forEach((field) => {
                    const errorMessages = err.errors[field];
                    switch (field) {
                        case "Title":
                            refTitle.current?.setCustomValidity(errorMessages[0]);
                            setTitleValidationMessage(errorMessages[0])
                            break;
                        default:
                            setErrorMessage("Something went wrong");
                            break;
                    }
                });
            } else {
                setErrorMessage("Something went wrong");
            }
        }
    };

    // TEST
    // const createDeckInBackground = async (deck: DeckResource) => {
    //     setTimeout(async () => {
    //         if (await createDeck(deck)) {
    //             showToast("Notification", "The deck was successfully generated");
    //         }
    //     }, 6000);
    // };

    async function handleImport(e: FormEvent) {
        e.preventDefault();
        setValidated2(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refFileInput.current?.setCustomValidity('')
        setImportValidationMessage('')
        try {
            const fileInput = refFileInput.current;
            const file = fileInput!.files![0];
            const deckContent = await readFile(file);
            const deck = JSON.parse(deckContent);
            if (await importDeck(deck)) {
                setValidated2(false);
                resetFileInput();
                setMode("create")
                updateBoard();
                handleClose();
            }
        } catch (error: any) {
            if (error instanceof TypeError) {
                refFileInput.current?.setCustomValidity("No file was chosen");
                setImportValidationMessage("No file was chosen");
            } else if (error instanceof SyntaxError) {
                refFileInput.current?.setCustomValidity("Error importing deck. The file is probably not a Json file.");
                setImportValidationMessage("Error importing deck. The file is probably not a Json file.")
            } else {
                setImportValidationMessage("Something went wrong");
            }
        }
    };

    async function handleGenerate(e: FormEvent) {
        e.preventDefault();
        setValidated3(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refPDFInput.current?.setCustomValidity('')
        setGenerateValidationMessage('');
        try {
            const fileInput = refPDFInput.current;
            const file = fileInput!.files![0];
            if (!file || file.type !== 'application/pdf') {
                refPDFInput.current?.setCustomValidity("Please choose a valid PDF file.");
                setGenerateValidationMessage("Please choose a valid PDF file.");
                return;
            }
            generateDeckInBackground(file, numberInput);
            setValidated3(false);
            resetFileInput();
            setNumberInput(1);
            setMode("create")
            handleClose();
        } catch (error: any) {
            if (error instanceof TypeError) {
                refPDFInput.current?.setCustomValidity("No file was chosen");
                setGenerateValidationMessage("No file was chosen");
            } else if (error instanceof SyntaxError) {
                refPDFInput.current?.setCustomValidity("Error generating deck. The file is probably not a PDF file.");
                setGenerateValidationMessage("Error generating deck. The file is probably not a PDF file.")
            } else {
                setGenerateValidationMessage("Something went wrong");
            }
        }
    }

    const generateDeckInBackground = async (file: File, numberInput: number) => {
        try {
            if (await generateDeck(file, numberInput)) {
                updateBoard();
                showToast("Notification", "The deck was successfully generated");
            }
        } catch (error: any) {
            showToast("Warning", "Something went wrong");
        }
    };

    const showToast = (header: any, msg: any) => {
        updateBoard();
        setToastMsg({ header, msg });
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setNumberInput(value);
    };

    const resetFileInput = () => {
        if (refFileInput.current) {
            refFileInput.current.value = "";
        }
        if (refPDFInput.current) {
            refPDFInput.current.value = "";
        }
    };

    const selectOptions = [
        { value: "create", label: "Create New Deck" },
        { value: "import", label: "Import Deck" },
        { value: "generate", label: "Generate Deck" },
    ];

    const handleModeChange = (newMode: string) => {
        setMode(newMode);
    };

    const resetEverything = () => {
        handleClose();
        setMode("create");
        resetFileInput();
        setValidated(false);
        setValidated2(false);
        setValidated3(false);
        setImportValidationMessage('');
        setNumberInput(1);
    };

    return (
        <>
            <Modal centered show={handleShow} onHide={resetEverything}>
                <Modal.Header closeButton>
                    <CustomSelect value={mode} onChange={handleModeChange} options={selectOptions} />
                </Modal.Header>
                <Modal.Body className="d-flex flex-column align-items-center">
                    {mode === "create" ? (
                        <Form validated={validated}>
                            <Form.Group className="form-inline">
                                <Row className="align-items-center">
                                    <Form.Label column xs="auto">
                                        Title:
                                    </Form.Label>
                                    <Col xs="auto">
                                        <Form.Control type="text" placeholder="Enter Title" id="title" ref={refTitle} />
                                        <Form.Control.Feedback type="invalid">{titleValidationMessage}</Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    ) : mode === "import" ? (
                        <Form validated={validated2}>
                            <Form.Group>
                                <InputGroup>
                                    <Form.Control type="file" ref={refFileInput} id="fileInput" />
                                    <InputGroup.Text>
                                        <i className="bi bi-filetype-json fs-5"></i>
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">{importValidationMessage}</Form.Control.Feedback>
                                </InputGroup> 
                            </Form.Group>
                        </Form>
                    ) : (
                        <Form validated={validated3}>
                            <Form.Group>
                                <Form.Label>Number of cards (max. 200)</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={numberInput} onChange={handleNumberChange} min={1} max={200} />
                                    <InputGroup.Text>/ 200</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <InputGroup>
                                    <Form.Control type="file" ref={refPDFInput} id="fileInput" />
                                    <InputGroup.Text>
                                        <i className="bi bi-filetype-pdf fs-5"></i>
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">{generateValidationMessage}</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    )}
                    {mode === "create" ? (
                        <small style={{ color: 'red' }}> {errorMessage}</small>
                    ) : null}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="primary" className="my-2 btn" onClick={mode === "create" ? handleCreate : mode === "import" ? handleImport : handleGenerate}>
                        {mode === "create" ? "Create" : mode === "import" ? "Import" : "Generate"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CreateDeckComp;
