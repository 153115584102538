import { retrieveJWT } from "../utils/jwt";
import { QuizRecord } from "../utils/resources";
const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
    const headers: any = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const jwt = retrieveJWT();
    if (jwt !== null) {
        headers.Authorization = `Bearer ${jwt}`;
    }
    return headers;
}

export async function getRecordById(quizRecordId: number): Promise<QuizRecord> {
    const url = `${HOST}/api/quiz/quizRecord?id=${quizRecordId}`;
    const response = await fetch(url, { headers: headers() });
    if (!response.ok) {
      throw new Error("Error in getRecordById");
    }
    const data = response.json();
    return data;
}

export async function getAllRecordsByQuizId(quizId: number): Promise<QuizRecord[]> {
  const url = `${HOST}/api/quizRecord/allrecords?id=${quizId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error in getAllRecordByQuizId");
  }
  const data = response.json();
  return data;
}



export async function getRecordByQuizId(quizId: number): Promise<QuizRecord> {
  const url = `${HOST}/api/quiz/quizRecord?id=${quizId}`;
  const response = await fetch(url, { headers: headers() });
  if (!response.ok) {
    throw new Error("Error in getRecordById");
  }
  const data = response.json();
  return data;
}

export async function createRecord(quizRecord: QuizRecord): Promise<QuizRecord>{
    console.log(quizRecord);
    const url = `${HOST}/api/quizRecord/create/`;
    const response = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(quizRecord),
    });
    if (!response.ok) {
        throw new Error("Error in createRecord");
    }
    const data = response.json();
    return data;
}

export async function updateRecord(quizRecord: QuizRecord): Promise<boolean> {
    const url = `${HOST}/api/quizRecord/update?id=${quizRecord.id}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(quizRecord),
    });
    if (!response.ok) {
      throw new Error("Error in updateRecord");
    }
    return response.ok;
  }

export async function deleteRecord(quizRecordId: number): Promise<boolean> {
    const url = `${HOST}/api/quizRecord/delete?id=${quizRecordId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers()
    });
    if (!response.ok) {
      throw new Error("Error in deleteRecord");
    }
    return response.ok;
  }
