import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function StatisticsDecksProgressComp(Props: { cardProgressDeck: { [key: string]: any; } | null }) {

  const [data, setData] = useState<{ labels: string[]; datasets: { label: string; data: number[]; backgroundColor: string; }[]; } | undefined>();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    if (Props.cardProgressDeck === null) {
      setLoading(true);
      return;
    }
    let progress = Props.cardProgressDeck;
    let labels: string[] = [];
    let notLearned: number[] = [];
    let hard: number[] = [];
    let good: number[] = [];
    let easy: number[] = [];
    for (let title in progress) {
      if (Props.cardProgressDeck.hasOwnProperty(title)) {
        const cardProgressArray = progress[title];
        labels.push(title);
        cardProgressArray[0] ? notLearned.push(cardProgressArray[0]) : notLearned.push(0);
        cardProgressArray[3] ? hard.push(cardProgressArray[3]) : hard.push(0);
        cardProgressArray[2] ? good.push(cardProgressArray[2]) : good.push(0);
        cardProgressArray[1] ? easy.push(cardProgressArray[1]) : easy.push(0);
      } else {
        console.log("nope");
      }
    }
    let progressData = {
      labels,
      datasets: [
        {
          label: 'Hard',
          data: hard,
          backgroundColor: 'rgba(255, 99, 132, 1)',
        },
        {
          label: 'Good',
          data: good,
          backgroundColor: 'rgba(255, 206, 86, 1)',
        },
        {
          label: 'Easy',
          data: easy,
          backgroundColor: 'rgba(75, 192, 192, 1)',
        },
        {
          label: 'Not Learned',
          data: notLearned,
          backgroundColor: 'rgba(54, 162, 235, 1)',
        },
      ],
    };
    setData(progressData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [Props.cardProgressDeck]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Deck Progress',
      },
    },
    responsive: true,
    maintainAspectRatio: false, 
    aspectRatio: 1.5, 
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className='chart-size'>
      {loading || !data ? (
        <div>Loading...</div>
      ) : (
        <Bar options={options} data={data} />
      )}
    </div>
  );
  
}


export default StatisticsDecksProgressComp;
