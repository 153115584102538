import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CustomSelect } from "./customElements/customSelect";
import { CardResource, CardType, cardTypeOptions } from "../utils/resources";
import { useCardTypeChangeBtnGrp } from "../hooks/useCardTypeChange";
import CreateCardStandardAnswer from "./CreateCardStandardAnswer";
import CreateCardSingleAnswer from "./CreateCardSingleAnswer";
import CreateCardMultipleAnswer from "./CreateCardMultipleAnswerComp";

interface CreateCardCompProps {
    index: number;
    deckID: string;
    titleMessages: Record<number, string>;
    contentMessages: Record<number, string>;
    checkMessages: Record<number, string>;
    inputMessages: Record<number, string>;
    onCardChange: (newCard: CardResource) => void;
}

function CreateCardComp({ index, deckID, titleMessages, contentMessages, checkMessages, inputMessages, onCardChange }: CreateCardCompProps) {
    let refTitle = useRef<HTMLInputElement>(null);
    const [answerContent, setAnswerContent] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const { selectedCardType, handleCardTypeChange } = useCardTypeChangeBtnGrp();

    const getAnswerLayout = () => {
        if (selectedCardType === CardType.MultipleChoice) {
            return <CreateCardMultipleAnswer callback={handleAnswerChange} cardData={null} validationMsg={contentMessages[index]} />
        } else if (selectedCardType === CardType.SingleChoice) {
            return <CreateCardSingleAnswer callback={handleAnswerChange} validationMsg={contentMessages[index]} />
        } else {
            return <CreateCardStandardAnswer callback={handleAnswerChangeStandard} currentAnswerContent={answerContent} validationMsg={contentMessages[index]} />;
        }
    }

    const handleAnswerChangeStandard = (event: { target: { value: SetStateAction<string>; }; }) => {
        setAnswerContent(event.target.value);
    }

    const handleAnswerChange = (content: string) => {
        setAnswerContent(content);
    }

    useEffect(() => {
        onCardChange({ title: String(title), content: answerContent, deckId: Number(deckID), type: selectedCardType })
        // eslint-disable-next-line
    }, [selectedCardType, answerContent, title]);

    useEffect(() => {
        setAnswerContent("")
        // eslint-disable-next-line
    }, [selectedCardType]);

    return (
        <div className="CreateCard-form-container">
            <Col>
                <Form className="CreateCard-form">
                    <div className="CreateCard-form-content">
                        <Form.Group>
                            <Row>
                                <Col className="CreateCard-form-title align-items-center">
                                    <Form.Label >Create Card</Form.Label>
                                </Col>
                                <Col className="CreateCard-form-title d-flex justify-content-center">
                                    <CustomSelect value={selectedCardType} onChange={handleCardTypeChange} options={cardTypeOptions} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="full-width">
                            <Form.Label>Question:</Form.Label>
                            <Form.Control className="titlefield" type="text" placeholder="Enter Title" id={"title"} ref={refTitle} isInvalid={!!titleMessages[index]} onChange={(e) => setTitle(e.target.value)} />
                            <Form.Control.Feedback type="invalid">{titleMessages[index]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="full-width">
                            {getAnswerLayout()}
                            <small style={{ color: 'red' }}>{checkMessages[index]} {inputMessages[index]}</small>
                        </Form.Group>
                    </div>
                </Form>
            </Col>
        </div >
    );
}

export default CreateCardComp;
