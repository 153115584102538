interface stateObject {
    [key: string]: any
  }

export function getTrimmedAnswers(content: string): string{
    let result = content;
    let found = result.indexOf("&true;");

    while(found > -1)
    {
        result = result.replace("&true;", '');
        found = result.indexOf("&true;");
    }
    found = result.indexOf("&OR;");

    while(found > -1)
    {
        result = result.replace("&OR;", '');
        found = result.indexOf("&OR;");
    }

    return result;
}

export function removeBoolean(content: string): string{
    let result = content;
    let found = result.indexOf("&true;");

    while(found > -1)
    {
        result = result.replace("&true;", '');
        found = result.indexOf("&true;");
    }

    return result;
}

export function getAllAnswers(content: string): string[]{
    let result = [];
    result = content.split("&OR;");
    for(let el of result)
    {
        el = el.trim();
    }
    return result
}

export function getCorrectAnswers(content: string): string[]{
    let answers = [];
    let result = [];
    answers = content.split("&OR;");
    for(let el of answers)
    {
        if(el.indexOf("&true;") >= 0 && el !== " "){
            el = el.trim().replace("&true;", '');
            result.push(el);
        }
    }
    return result
}

export function getIncorrectAnswers(content: string): string[]{
    let answers = [];
    let result = [];
    answers = content.split("&OR;");
    for(let el of answers)
    {
        if(el.indexOf("&true;") === -1 && el !== " ") result.push(el.trim());
    }
    return result
}

export function getOldAnswers(correctAnswers: string[], incorrectAnswers: string[]): stateObject{
    const result = {} as stateObject;

    const lengthFirstArray:number = correctAnswers.length;
    for(let i = 0; i < correctAnswers.length; i++){
        const item = i+1;
        const propName = "answeroption"+item;
        result[propName] = correctAnswers[i];
    }
    for(let i = 0; i < incorrectAnswers.length; i++){
        const item = i+1+lengthFirstArray;
        const propName = "answeroption"+item;
        result[propName] = incorrectAnswers[i];        
    }

    return result;
}

export function getOldChecks(correctAnswers: string[], incorrectAnswers: string[]): stateObject{
    const result = {} as stateObject;

    const lengthFirstArray:number = correctAnswers.length;
    for(let i = 0; i < correctAnswers.length; i++){
        const item = i+1;
        const propName = "answeroption"+item;
        result[propName] = true;
    }
    for(let i = 0; i < incorrectAnswers.length; i++){
        const item = i+1+lengthFirstArray;
        const propName = "answeroption"+item;
        result[propName] = false;        
    }

    return result;
}

export function randomizeAnswers(answers: string[]): string[] {
    const shuffledAnswers = [...answers];
  
    for (let i = shuffledAnswers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledAnswers[i], shuffledAnswers[j]] = [shuffledAnswers[j], shuffledAnswers[i]];
    }
  
    return shuffledAnswers;
  }