import { Modal, Table } from "react-bootstrap";
import { CardType, QuestionResource, QuizResource } from "../utils/resources";
import React, { useState } from "react";
import { getQuizById } from "../services/quizapi";
import { getTrimmedAnswers } from "../utils/cardanswerutils";

type QuizOverviewProps = {
    quizId: number | null
    show: boolean
    onHide: () => void
}

export default function QuizOverviewComp({ quizId, show, onHide }: QuizOverviewProps) {
    const [quiz, setQuiz] = useState<QuizResource | null>(null);
    async function load() {
        try {
            if (quizId !== null) {
                const getQuiz = await getQuizById(quizId);
                setQuiz(getQuiz)
            }
        } catch (error) {
            console.error("Error loading quiz", error);
        }
    }

    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [quizId]);

    if (quizId === null || quiz === null) {
        return null;
    }
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Quiz Overview: {quiz.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    {quiz ?
                        <>
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quiz!.questions.map((question: QuestionResource) => (
                                    <tr key={question.id}>
                                        <td>{question.title}</td>
                                        <td>{question.type === CardType.Standard ? question.content : getTrimmedAnswers(question.content)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </>
                        :
                        <></>
                    }
                </Table>
            </Modal.Body>
        </Modal>
    );
}
