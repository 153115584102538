import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { register } from "../services/accountapi";
import { AccountResource } from "../utils/resources";
import React, { FormEvent, useRef, useState } from "react";
import { CustomImage } from "../components/customElements/customImage";
import CropImageComp from "../components/CropImageComp";
import { useDialogToggler } from "../hooks/useDialogToggler";
import ValidationFailedError from "../utils/validationFailedError";
import logo from "../assets/img/logo_gruen_horizontal.svg";

function SignUpPage() {
    const navigate = useNavigate();
    const [picture, setPicture] = useState<any>(null);
    const [cropped, setCropped] = useState<any>(null);
    const [image, setImage] = useState<any>(null);
    const { showDialog, handleShow, handleClose } = useDialogToggler();

    const [validated, setValidated] = useState(false);
    const [nameValidationMessage, setNameValidationMessage] = useState("");
    const [emailValidationMessage, setEmailValidationMessage] = useState("");
    const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const refEmail = useRef<HTMLInputElement>(null);
    const refPassword = useRef<HTMLInputElement>(null);
    const refName = useRef<HTMLInputElement>(null);

    const switchPage = async () => {
        navigate("/login");
    };

    const handleRegister = async (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        refName.current?.setCustomValidity('');
        refEmail.current?.setCustomValidity('');
        refPassword.current?.setCustomValidity('');
        setNameValidationMessage('')
        setEmailValidationMessage('')
        setPasswordValidationMessage('')
        setErrorMessage('');
        const accountRes = {
            accountmail: refEmail.current!.value,
            password: refPassword.current!.value,
        } as AccountResource

        try {
            if (await register(refName.current!.value, accountRes, image)) {
                switchPage();
            }
        } catch (err: any) {
            if (err instanceof ValidationFailedError) {
                const errorFields = Object.keys(err.errors);
                errorFields.forEach((field) => {
                    const errorMessages = err.errors[field];
                    switch (field) {
                        case "Name":
                            refName.current?.setCustomValidity(errorMessages[0]);
                            setNameValidationMessage(errorMessages[0])
                            break;
                        case "Account.AccountMail":
                            refEmail.current?.setCustomValidity(errorMessages[0]);
                            setEmailValidationMessage(errorMessages[0])
                            break;
                        case "Account.Password":
                            refPassword.current?.setCustomValidity(errorMessages[0]);
                            setPasswordValidationMessage(errorMessages[0])
                            break;
                        default:
                            setErrorMessage("Something went wrong");
                            break;
                    }
                });
            } else {
                setErrorMessage("Something went wrong");
            }
        }
    };

    const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicture(reader.result);
                handleShow();
            };
            reader.readAsDataURL(file);
        }
        const fileInput = document.getElementById("photo-upload") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const handleImage = (crop: any) => {
        if (crop) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCropped(reader.result);
            };
            reader.readAsDataURL(crop);
        }
        setImage(crop);
        handleClose();
    };

    return (
        <Container className="Authentication-form-container">
            <Form className="Authentication-form" validated={validated}>
                <div className="Authentication-form-content">
                    <img src={logo} alt="" />
                    <h3 className="Authentication-form-title">Sign Up</h3>
                    <div className="switchLabel">
                        Already registered?{" "}
                        <span className="link-success" onClick={switchPage}>
                            Sign In
                        </span>
                    </div>
                    <Row className="row-cols-2 mt-3">
                        <Col>
                            <Form.Label>Name</Form.Label>
                            <Form.Control placeholder="John Doe" type="name" id={"refName"} onChange={() => setValidated(false)} ref={refName} />
                            <Form.Control.Feedback type="invalid">{nameValidationMessage}</Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label className="d-flex justify-content-center">Upload image</Form.Label>
                            <CustomImage onChange={handlePictureChange} src={cropped} />
                        </Col>
                    </Row>
                    <Form.Group className="mt-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control placeholder="name@example.com" type="email" id={"refEmail"} isInvalid={!!errorMessage} onChange={() => setValidated(false)} ref={refEmail} />
                        <Form.Control.Feedback type="invalid">{emailValidationMessage}</Form.Control.Feedback>
                    </Form.Group>
                    <OverlayTrigger placement={'bottom'} overlay={<Tooltip> Das Passwort muss ein Sonderzeichen, einen Klein - und Großbuchstaben und mindestens 8 Zeichen lang sein. </Tooltip>}>
                        <Form.Group className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control placeholder="1234" type="password" id={"refPassword"} onChange={() => setValidated(false)} ref={refPassword} />
                            <Form.Control.Feedback type="invalid">{passwordValidationMessage}</Form.Control.Feedback>
                        </Form.Group>
                    </OverlayTrigger>
                    <small style={{ color: 'red' }}> {errorMessage}</small>
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="success" type="submit" onClick={handleRegister}>
                            Register
                        </Button>
                    </div>
                </div>
            </Form>
            {showDialog && <CropImageComp imageSrc={picture} onImageProcessed={handleImage} onClose={handleClose} />}
        </Container>
    );
}

export default SignUpPage;
