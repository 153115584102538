import { FormEvent, useEffect, useRef, useState } from "react";
import { useCardTypeChange } from "../hooks/useCardTypeChange";
import { deleteCard, getCardById, updateCard } from "../services/cardapi";
import { CardResource, CardType } from "../utils/resources";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form } from "react-bootstrap";
import { CustomDelete } from "../components/customElements/customDelete";
import { useDialogToggler } from "../hooks/useDialogToggler";
import CustomPreLoader from "../components/customElements/customPreLoader";
import CardAnswersComp from "../components/CardAnswersComp";
import ValidationFailedError from "../utils/validationFailedError";
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";
import { removeBoolean } from "../utils/cardanswerutils";


function CardPage() {
    const cardID = useParams().cardID!
    const navigate = useNavigate();
    const [cardData, setCardData] = useState<CardResource | null>(null);
    let refTitle = useRef<HTMLInputElement>(null);
    let refContent = useRef<HTMLTextAreaElement>(null)
    const { selectedCardType } = useCardTypeChange();
    const { showDialog, handleShow, handleClose } = useDialogToggler();
    const [answerContent, setAnswerContent] = useState<string>("");
    const [titleValidationMessage, setTitleValidationMessage] = useState("");
    const [contentValidationMessage, setContentValidationMessage] = useState("");
    const [checkValidationMessage, setCheckValidationMessage] = useState("");
    const [inputValidationMessage, setInputValidationMessage] = useState("");
    const { setBreadCrumb } = useBreadCrumbContext()
    const [errorMessage, setErrorMessage] = useState("");

    async function load() {
        try {
            const data = await getCardById(Number(cardID));
            setCardData(data);
        } catch (error) {
            console.error("Error fetching card data");
        }
    }

    useEffect(() => {
        load();
        setBreadCrumb(generateBreadcrumbs())
        // eslint-disable-next-line
    }, []);

    async function handleEdit(e: FormEvent) {
        e.preventDefault();
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.stopPropagation(); return;
        }
        setTitleValidationMessage('')
        setContentValidationMessage('');
        setCheckValidationMessage('');
        setInputValidationMessage('');
        setErrorMessage('');
        let cardRes;
        if (cardData?.type === CardType.Standard) {
            cardRes = {
                id: cardData?.id,
                deckId: cardData?.deckId,
                type: selectedCardType,
                title: refTitle.current?.value,
                content: refContent.current?.value,
            } as CardResource;
        } else {
            cardRes = {
                id: cardData?.id,
                deckId: cardData?.deckId,
                type: cardData?.type,
                title: refTitle.current?.value,
                content: answerContent,
            } as CardResource;
        }
        try {
            validateContentFormat(cardRes.content)
            validateChecks(cardRes)
            if (await updateCard(cardRes)) {
                navigate(-1);
            }
        } catch (err: any) {
            if (err instanceof ValidationFailedError) {
                const errorFields = Object.keys(err.errors);
                errorFields.forEach((field) => {
                    const errorMessages = err.errors[field];
                    switch (field) {
                        case "Title":
                            setTitleValidationMessage(errorMessages[0])
                            break;
                        case "Content":
                            setContentValidationMessage(errorMessages[0])
                            break;
                        case "Check":
                            setCheckValidationMessage(errorMessages[0]);
                            break;
                        case "Input":
                            setInputValidationMessage(errorMessages[0]);
                            break;
                        default:
                            setErrorMessage("Something went wrong");
                            break;
                    }
                });
            } else {
                setErrorMessage("Something went wrong");
            }
        }
    }

    function validateChecks(card: CardResource): void {
        const errors: Record<string, string[]> = {};

        if (!card.content.includes("&true;") && card.type !== CardType.Standard && card.content !== "") {
            errors["Check"] = ["At least one checkbox for a correct answer must be checked"];
        }
        if (Object.keys(errors).length > 0) {
            throw new ValidationFailedError(400, "Validation failed", "", "ValidationFailed", errors);
        }
    }

    function validateContentFormat(content: string): void {
        const contentWithoutTrue = removeBoolean(content);
        if (contentWithoutTrue.includes('&OR;')) {
            const parts = contentWithoutTrue.split('&OR;').slice(0, -1);
            const temp =  contentWithoutTrue.split('&OR;').slice(0, -1);
            const invalidParts = parts.filter(part => !part.trim());
            if (invalidParts.length > 0 || temp.length < 2) {
                throw new ValidationFailedError(400, "Validation failed", "", "ValidationFailed", {
                    [`Input`]: ["Some answer fields have not been filled"]
                });
            }
        }
    }

    const handleDelete = async () => {
        handleClose();
        await deleteCard(cardData!.id!);
        navigate(-1);
    };

    const handleAnswerChange = (content: string) => {
        setAnswerContent(content);
    }

    if (!cardData) {
        return <CustomPreLoader />;
    } else {
        return (
            <>
                <div className="CreateCard-form-container">
                    <Col>
                        <Form className="CreateCard-form">
                            <div className="CreateCard-form-content">
                                <h3 className="CreateCard-form-title">Edit Card</h3>
                                <Form.Group className="full-width">
                                    <Form.Label>Question:</Form.Label>
                                    <Form.Control className="titlefield" type="text" placeholder="Enter Title" id={"title"} ref={refTitle} defaultValue={cardData?.title} isInvalid={!!titleValidationMessage} />
                                    <Form.Control.Feedback type="invalid">{titleValidationMessage}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="full-width">
                                    {cardData.type === CardType.Standard ?
                                        <>
                                            <Form.Label>Answer:</Form.Label>
                                            <Form.Control className="textfield" as="textarea" placeholder="Enter Content" id={"content"} ref={refContent} defaultValue={cardData?.content} isInvalid={!!contentValidationMessage} />
                                            <Form.Control.Feedback type="invalid">{contentValidationMessage}</Form.Control.Feedback>
                                        </> :
                                        <CardAnswersComp card={cardData} disabled={false} callback={handleAnswerChange} validationMsg={contentValidationMessage}></CardAnswersComp>}
                                    <small style={{ color: 'red' }}> {checkValidationMessage} {errorMessage } {inputValidationMessage}</small>
                                </Form.Group>
                            </div>

                        </Form>
                        <div className="mt-3 d-flex justify-content-start">
                            <Button className="card-editBtn m-3" variant="secondary" onClick={() => navigate(-1)}>
                                Back
                            </Button>
                            <Button className="card-editBtn m-3" variant="danger" onClick={handleShow}>
                                Delete
                            </Button>
                            <Button className="card-editBtn m-3" type="submit" variant="success" onClick={handleEdit}>
                                Save
                            </Button>
                        </div>
                    </Col>
                </div>
                <CustomDelete showDialog={showDialog} handleClose={handleClose} handleDelete={handleDelete} />
            </>
        );
    }
}

export default CardPage;