import { Card } from "react-bootstrap";
import { getCorrectAnswers, getTrimmedAnswers } from "../utils/cardanswerutils";
import { CardResource, CardType, QuestionResource } from "../utils/resources";
import EditCardMultipleAnswerComp from "./EditCardMultipleAnswerComp";
import EditCardSingleAnswerComp from "./EditCardSingleAnswerComp";
import { Fragment } from "react";


function CardAnswersComp(prop: { card: CardResource | QuestionResource, disabled: boolean, callback: any, validationMsg: string }) {
    const getContent = () => {
        if (!prop.disabled) {
            if (prop.card.type === CardType.MultipleChoice) {
                return <EditCardMultipleAnswerComp callback={prop.callback} cardData={prop.card} validationMsg={prop.validationMsg}></EditCardMultipleAnswerComp>
            } else if (prop.card.type === CardType.SingleChoice) {
                return <EditCardSingleAnswerComp callback={prop.callback} cardData={prop.card} validationMsg={prop.validationMsg}></EditCardSingleAnswerComp>
            }
        }
        if (prop.card.type === CardType.MultipleChoice) {
            let correctAnswers = getCorrectAnswers(prop.card.content);
            let forms: JSX.Element[] = [];
            if (prop.disabled) {
                for (let i in correctAnswers) {
                    const nameProp = "correctoption" + i;
                    const keyForm = nameProp + "form";
                    forms.push(
                        <div className='text-center' key={keyForm}>{correctAnswers[i]}</div>
                    )
                }
            }
            return forms;
        } else if (prop.card.type === CardType.SingleChoice) {
            let correctAnswers = getCorrectAnswers(prop.card.content);
            let forms: JSX.Element[] = [];
            if (prop.disabled) {
                for (let i in correctAnswers) {
                    const nameProp = "correctoption" + i;
                    const keyForm = nameProp + "form";
                    forms.push(
                        <div className='text-center' key={keyForm}>{correctAnswers[i]}</div>
                    )
                }
            }
            return forms;
        } else {
            return <Card.Text>Content: {getTrimmedAnswers(prop.card.content)}</Card.Text>
        }
    }
    return (
        <Fragment>
            {getContent()}
        </Fragment>
    );
}

export default CardAnswersComp;