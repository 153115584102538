import { retrieveJWT } from "../utils/jwt";
import { CardResource, DeckResource } from "../utils/resources";

const HOST = process.env.REACT_APP_API_SERVER_URL;

function headers() {
    const headers: any = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const jwt = retrieveJWT();
    if (jwt !== null) {
        headers.Authorization = `Bearer ${jwt}`;
    }
    return headers;
}

export async function exportCard(card: CardResource) {
    const url = `${HOST}/api/card/export/`;
    const response = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(card),
    });
    if (!response.ok) {
        throw new Error("Error connecting to Host");
    }
    const data = response.json();
    return data;
}

export async function importCard(card: CardResource, deckId: number) {
    const url = `${HOST}/api/card/import?deckid=${deckId}`;
    const response = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(card),
    });
    if (!response.ok) {
        const errMsg = await response.json();
        throw new Error(errMsg.errors);
    }
    return response.ok;
}

export async function exportDeck(deck: DeckResource) {
    const url = `${HOST}/api/deck/export`;
    const response = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(deck),
    });
    if (!response.ok) {
        throw new Error("Error connecting to Host");
    }
    const data = response.json();
    return data;
}

export async function importDeck(deck: DeckResource) {
    const url = `${HOST}/api/deck/import`;
    const response = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(deck),
    });
    if (!response.ok) {
        const errMsg = await response.json();
        throw new Error(errMsg.errors);
    }
    return response.ok;
}