import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";

interface stateObject {
  [key: string]: any
}

function CreateCardSingleAnswer(prop: { callback: any, validationMsg: string }) {
  const [answersAmount, setAnswersAmount] = useState<number>(0);
  const [answerContent, setAnswerContent] = useState({ content: "" });
  const [singleChoiceAnswers, setInputValues] = useState<stateObject>({});
  const [singleChoiceChecks, setCheckValues] = useState<stateObject>({});

  const handleInputChange = (event: { target: { name: string; value: string; }; }) => {
    setInputValues({
      ...singleChoiceAnswers,
      [event.target.name]: event.target.value
    })
  }

  const handleCheckChange = (event: { target: { id: string; checked: boolean; }; }) => {
    const updatedCheckValues = {
      ...singleChoiceChecks,
      [event.target.id]: event.target.checked
    };
    setCheckValues(updatedCheckValues);
  }

  function deleteLastAnswer() {
    const number = Number(answersAmount) + 2
    const propName = "answeroption" + number
    delete singleChoiceAnswers[propName];
    delete singleChoiceChecks[propName];
  }

  function updateAnswerStuff() {
    for (const key in singleChoiceAnswers) {
      const keyTyped = key as keyof typeof singleChoiceAnswers; // singleChoiceAnswers.key same as singleChoiceCheck.key
      if (singleChoiceChecks[keyTyped] && singleChoiceAnswers[keyTyped].indexOf("&true;") < 0) {
        singleChoiceAnswers[keyTyped] = "&true;" + singleChoiceAnswers[keyTyped];
      } else if (!singleChoiceChecks[keyTyped]) {
        singleChoiceAnswers[keyTyped] = singleChoiceAnswers[keyTyped].replace(/^&true;/, '');
      }
    }
    let finalAnswer = "";
    for (const key in singleChoiceAnswers) {
      const keyTyped = key as keyof typeof singleChoiceAnswers;
      if (singleChoiceAnswers[keyTyped]) {
        finalAnswer = finalAnswer + singleChoiceAnswers[keyTyped] + " &OR; "
      }
    }
    setAnswerContent({ ...answerContent, content: finalAnswer });
    prop.callback(answerContent.content);
    for (let i = answersAmount + 3; i < Object.keys(singleChoiceAnswers).length + 1; i++) {
      const nameProp = "answeroption" + i
      delete singleChoiceAnswers[nameProp];
    }
  }

  useEffect(() => {
    updateAnswerStuff();
    // eslint-disable-next-line
  }, [singleChoiceAnswers, singleChoiceChecks, answerContent.content, answersAmount]);

  const getForms = () => {
    let stuff: JSX.Element[] = [];
    for (let i = 1; i < answersAmount + 3; i++) {
      const nameProp = "answeroption" + i
      const keyForm = nameProp + "form";
      stuff.push(
        <div key={keyForm}>
          <Form.Label > Answer Option {i}: </Form.Label>
          <Col>
            <InputGroup>
              <InputGroup.Radio name="radiocheck" id={nameProp} onChange={handleCheckChange} />
              <Form.Control type="text" placeholder="Enter Answer" name={nameProp} onChange={handleInputChange} isInvalid={!!prop.validationMsg} />
              <Form.Control.Feedback type="invalid">{prop.validationMsg}</Form.Control.Feedback>
            </InputGroup>
          </Col>
        </div>
      );
    }
    return stuff;
  }

  const getDeleteButton = () => {
    if (answersAmount > 0) {
      return (
        <Button className="card-plus-minus m-3" variant="secondary" onClick={() => { setAnswersAmount(answersAmount - 1); deleteLastAnswer(); }}>
          <i className="bi bi-dash-circle"></i>
        </Button>
      );
    }
  }

  return (
    <>
      <Form.Group className="contentfield_singlechoice">
        {getForms()}
        <Button className="card-plus-minus m-3" variant="secondary" onClick={() => setAnswersAmount(answersAmount + 1)}>
          <i className="bi bi-plus-circle"></i>
        </Button>
        {getDeleteButton()}
        <Form.Control disabled value={answerContent.content} name="textareaInput" className="d-none textfield" as="textarea" placeholder="Enter Answer" id={"content"} />
      </Form.Group>
    </>
  );
}

export default CreateCardSingleAnswer;