import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';

interface CropImageProps {
  imageSrc: string;
  onImageProcessed: (croppedImage: any) => void;
  onClose: () => void;
}

const CropImageComp = ({ imageSrc, onImageProcessed, onClose }: CropImageProps) => {
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  const onCropChange = (newCrop: any) => {
    setCrop(newCrop);
  };

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCompletedCrop(croppedAreaPixels);
  };

  const handleSave = async () => {
    const newCroppedImage = await getCroppedImage(imageSrc, completedCrop);
    onImageProcessed && onImageProcessed(newCroppedImage);
    onClose();
  };

  const getCroppedImage = async (imageSrc: string, completedCrop: any): Promise<File | null> => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          resolve(null);
          return;
        }

        ctx.drawImage(
          image,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height
        );
        canvas.toBlob((blob) => {
          if (blob) {
            const fileName = 'cropped_image.jpg';
            const croppedFile = new File([blob], fileName, { type: 'image/jpeg' });
            resolve(croppedFile);
          } else {
            resolve(null);
          }
        }, 'image/jpeg');
      };
    });
  };

  return (
    <Modal centered show={true} backdrop="static">
      <Modal.Body className="d-flex justify-content-center" style={{  height: 400 }}>
        {!!imageSrc && (
          <div className='d-flex justify-content-center'>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={1.5}
            aspect={1 / 1}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            showGrid={false}
          />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div>
          <Button variant="success" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CropImageComp;
