import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardRating, CardResource, CardStatisticsResource, CardType, DeckResource } from '../utils/resources';
import { getDeckById } from '../services/deckapi';
import { Badge, Button, Card, Fade, ProgressBar } from 'react-bootstrap';
import { getCardStatisticsByCardId, updateCardStatisticsByCardId } from '../services/statisticsapi';
import { cardRatingToString, cardTypeToString } from '../services/cardapi';
import CardAnswersComp from '../components/CardAnswersComp';
import CardAnswersClickDummyComp from '../components/CardAnswersClickDummyComp';
import { useBreadCrumbContext } from "../utils/Context";
import { generateBreadcrumbs } from "../utils/breadCrumb";

function StartDeckPage() {
    const deckID = useParams().deckID!
    const navigate = useNavigate();
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [deck, setDeck] = useState<DeckResource | null>(null)
    const [cards, setCards] = useState<CardResource[] | null>(null);
    const [cardStatisticsData, setCardStatisticsData] = useState<CardStatisticsResource | null>(null);
    const [showCardContent, setShowCardContent] = useState(false);
    const [showQuestion, setShowQuestion] = useState(true);
    const { setBreadCrumb } = useBreadCrumbContext()

    async function load() {
        try {
            const deckRes = await getDeckById(Number(deckID))
            setDeck(deckRes)
            setCards(deckRes.cards)
            const cardStatisticsRes = await getCardStatisticsByCardId(deckRes.cards[currentCardIndex].id!);
            setCardStatisticsData(cardStatisticsRes);
        } catch (err) {
            setCards(null)
            setCardStatisticsData(null)
            setDeck(null);
        }
    }

    useEffect(() => {
        load();
        setBreadCrumb(generateBreadcrumbs())
        // eslint-disable-next-line
    }, []);

    const handleDifficulty = async (cardRating: CardRating) => {
        setShowQuestion(false);
        const cardStatisticsRes = {
            id: cardStatisticsData!.id,
            cardId: cards![currentCardIndex].id,
            rating: cardRating,
            points: cardStatisticsData?.points
        } as CardStatisticsResource;

        if (cardStatisticsRes.id !== 0 || cardStatisticsData?.id !== null) {
            await updateCardStatisticsByCardId(cardStatisticsRes)
        }
        if (cards && currentCardIndex < cards.length - 1) {
            setCurrentCardIndex((prevIndex) => prevIndex + 1);
            const cardStatisticsRes = await getCardStatisticsByCardId(cards![currentCardIndex + 1].id!);
            setCardStatisticsData(cardStatisticsRes);
            setShowQuestion(true);
            setShowCardContent(false);
        }
        if (currentCardIndex === cards!.length - 1) {
            navigate('/dashboard');
        }
    };

    const handleAnswer = () => {
        setShowCardContent(true);
    };

    return (
        <Card className='m-0 p-0'>
            <div className='learnCard justify-content-center m-5'>
                {cards && cards.length > 0 && (
                    <>
                        <div className='row mt-2'>
                            <div className="col-sm-11">
                                <ProgressBar className='learnprogressbar mt-5' variant={(currentCardIndex === cards.length - 1 && showCardContent) ? "success" : "warning"} now={(currentCardIndex + 1) / cards.length * 100} />
                            </div>
                            <div className="col-sm-1 mt-3 p-1">
                                <i className="bi bi-person-fill fs-1 pl-1 mb-0"></i>
                                <span className='mt-0'>+{cardStatisticsData?.points}xp</span>
                            </div>
                        </div>
                        <div>
                            <p className="text-center m-2">
                                <i className="bi bi-files me-1 fs-5"></i>
                                {deck?.title}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Badge
                                bg={cardStatisticsData?.rating === 0 ? "dark" : cardStatisticsData?.rating === 1 ? "success" : cardStatisticsData?.rating === 2 ? "warning" : "danger"}
                                className="align-items-center">
                                {cardStatisticsData?.rating !== undefined ? cardRatingToString(cardStatisticsData.rating) : 'N/A'}
                            </Badge>
                        </div>
                        <div>
                            <h1 className="text-center">{cards[currentCardIndex].title}</h1>
                            {cards[currentCardIndex].type === CardType.Standard ?
                                showQuestion && (
                                    <span></span>
                                ) :
                                showQuestion && (
                                    <>
                                        <div className='m-1'>
                                            {cardTypeToString(cards[currentCardIndex].type)} Question
                                            {cards[currentCardIndex].type === 0 && (
                                                <span> (several correct answers possible)</span>
                                            )}
                                            {cards[currentCardIndex].type === 1 && (
                                                <span> (only one correct answer possible)</span>
                                            )}
                                        </div>
                                        <CardAnswersClickDummyComp card={cards[currentCardIndex]} callback={null} />
                                    </>
                                )}
                        </div>
                        <Fade in={showCardContent}>
                            <div className="m-1 align-items-center">
                                <Card border="success" className='card'>
                                    <Card.Body className='cardBody'>
                                        <div className="cardContent">
                                            {cards[currentCardIndex].type === CardType.Standard ?
                                                showCardContent && (
                                                    <Card.Text className='text-center'>{cards[currentCardIndex].content}</Card.Text>
                                                ) :
                                                showCardContent && (
                                                    <CardAnswersComp card={cards[currentCardIndex]} disabled={true} callback={false} validationMsg={''} />
                                                )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Fade>
                        {showCardContent ? (
                            <div className='d-flex justify-content-center flex-wrap m-4'>
                                <Button variant='success' className="learnBtn m-3" onClick={() => handleDifficulty(CardRating.Easy)}>Easy</Button>
                                <Button variant='warning' className="learnBtn m-3" onClick={() => handleDifficulty(CardRating.Good)}>Good</Button>
                                <Button variant='danger' className="learnBtn m-3" onClick={() => handleDifficulty(CardRating.Hard)}>Hard</Button>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-center flex-wrap m-3'>
                                <Button variant='secondary' className="learnBtn m-1" onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                                <Button variant='primary' className="learnBtn m-1" onClick={handleAnswer}>
                                    Show Answer
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Card>
    );
}

export default StartDeckPage;
