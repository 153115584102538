class ValidationFailedError extends Error {
  status: number;
  title: string;
  traceId: string;
  type: string;
  errors: Record<string, string[]>;

  constructor(status: number, title: string, traceId: string, type: string, errors: Record<string, string[]>) {
    super("Validation failed");
    this.status = status;
    this.title = title;
    this.traceId = traceId;
    this.type = type;
    this.errors = errors;
  }
}

export default ValidationFailedError;
