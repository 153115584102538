import React, { useEffect, useState } from 'react';
import { DeckResource, QuizResource } from '../utils/resources';
import { Modal, Button, Form } from 'react-bootstrap';
import { createQuestionsWithDeck } from '../services/questionapi';
import { createQuiz } from '../services/quizapi';
import { useLoginContext } from '../utils/Context';
import { getProfileById } from '../services/profileapi';
import { createRecord } from '../services/quizrecordapi';
import ValidationFailedError from '../utils/validationFailedError';

type CreateQuizProps = {
  show: boolean
  onHide: () => void
}

export default function CreateQuizComp({ show, onHide }: CreateQuizProps) {
  const { loginInfo } = useLoginContext();
  const profileId = Number(loginInfo?.profileId);
  // eslint-disable-next-line
  const [quizzes, setQuizzes] = useState<QuizResource[] | null>(null);
  const [quiz, setQuiz] = useState<QuizResource>({ title: '', creatorId: profileId, token: "", profileIds: [], questions: [], records: [] });
  const [decks, setDecks] = useState<DeckResource[] | null>(null);
  const [selectedDeckId, setSelectedDeckId] = useState<number | null>(null);
  const [validationMessage, setValidationMessage] = useState("");

  async function returnQuizzes() {
    try {
      const getProfile = await getProfileById(profileId);
      if (getProfile.quizzes) {
        setQuizzes(getProfile.quizzes);
        return getProfile.quizzes;
      }
      return null;
    } catch (error) {
      console.error("Error loading profile", error);
      setQuizzes(null);
      return null;
    }
  }

  async function load() {
    try {
      const getProfile = await getProfileById(profileId);
      if (getProfile.quizzes) {
        setQuizzes(getProfile.quizzes);
      }
      if (getProfile.decks) {
        setDecks(getProfile.decks);
      }
    } catch (error) {
      console.error("Error loading profile", error);
      setQuizzes(null);
      setDecks(null);
    }
  }

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, [profileId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuiz({
      ...quiz,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      e.stopPropagation(); return;
    }
    setValidationMessage("")
    try {
      var randomstring = require("randomstring");
      const generatedToken = randomstring.generate(5);
      quiz.token = generatedToken;
      const createdQuiz = await createQuiz(quiz);
      if (createdQuiz) {
        const updatedQuizzes = await returnQuizzes();
        const latestQuiz = updatedQuizzes && updatedQuizzes.length > 0 ? updatedQuizzes[updatedQuizzes.length - 1] : null;
        if (latestQuiz !== null && latestQuiz.id !== undefined) {
          await createQuestionsWithDeck(selectedDeckId!, latestQuiz.id);
          // Record wird für den Ersteller automatisch erstellt
          await createRecord({ profileId: profileId, quizId: latestQuiz.id, score: 0 });
          onHide();
          await load();
        }
      }
    } catch (err: any) {
      if (err instanceof ValidationFailedError) {
        const errorFields = Object.keys(err.errors);
        errorFields.forEach((field) => {
          const errorMessages = err.errors[field];
          switch (field) {
            case "Title":
              setValidationMessage(errorMessages[0])
              break;
            default:
              setValidationMessage("Something went wrong");
              break;
          }
        });
      } else {
        setValidationMessage("Something went wrong");
      }
    } finally {
      quiz.title = "";
      quiz.token = "";
    }
  };

  return (
    <Modal show={show} onHide={() => { onHide(); quiz.title = ""; setValidationMessage("") }} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Choose a Deck:</Form.Label>
          <Form.Control
            as="select"
            name="selectedDeckId"
            value={selectedDeckId === null ? "" : selectedDeckId}
            onChange={(e) => setSelectedDeckId(e.target.value === "" ? null : Number(e.target.value))}>
            <option value="">Select a Deck</option>
            {decks && decks.map((deck) => (
              <option key={deck.id} value={deck.id}>
                {deck.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form onSubmit={handleSubmit} >
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" name="title" value={quiz.title} onChange={handleChange} isInvalid={!!validationMessage} />
            <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" variant="primary" disabled={selectedDeckId == null}>Create Quiz</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
