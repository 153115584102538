import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { CardResource, QuestionResource } from "../utils/resources";
import { getCorrectAnswers, getIncorrectAnswers, getOldAnswers, getOldChecks } from "../utils/cardanswerutils";


interface stateObject {
  [key: string]: any
}

function EditCardSingleAnswerComp(prop: { callback: any, cardData: CardResource | null | QuestionResource, validationMsg: string }) {
  const [answersAmount, setAnswersAmount] = useState<number>(0);
  const [answerContent, setAnswerContent] = useState({ content: "" });
  const [answerOldContent, setAnswerOldContent] = useState("");
  const [multipleChoiceAnswers, setInputValues] = useState<stateObject>({});
  const [multipleChoiceChecks, setCheckValues] = useState<stateObject>({});
  const [flag, setFlag] = useState<boolean>(true);

  const handleInputChange = (event: { target: { name: string; value: string; }; }) => {
    setInputValues({
      ...multipleChoiceAnswers,
      [event.target.name]: event.target.value
    })
  }

  const handleCheckChange = (event: { target: { id: string; checked: boolean; }; }) => {
    const { id, checked } = event.target;
    let temp = { ...multipleChoiceChecks };
    Object.keys(temp).forEach(key => {
      temp[key] = key === id ? checked : false;
    });
    setCheckValues(temp);
  };

  function deleteLastAnswer() {
    const number = Number(answersAmount)
    const propName = "answeroption" + number
    delete multipleChoiceAnswers[propName];
    delete multipleChoiceChecks[propName];
  }

  function updateAnswerStuff() {
    for (const key in multipleChoiceAnswers) {
      const keyTyped = key as keyof typeof multipleChoiceAnswers; // multipleChoiceAnswers.key same as multipleChoiceChecks.key
      if (multipleChoiceChecks[keyTyped] && multipleChoiceAnswers[keyTyped].indexOf("&true;") < 0) {
        multipleChoiceAnswers[keyTyped] = "&true;" + multipleChoiceAnswers[keyTyped];
      } else if (!multipleChoiceChecks[keyTyped]) {
        multipleChoiceAnswers[keyTyped] = multipleChoiceAnswers[keyTyped].replace(/^&true;/, '');
      }
    }
    let finalAnswer = "";
    for (const key in multipleChoiceAnswers) {
      const keyTyped = key as keyof typeof multipleChoiceAnswers;
      finalAnswer = finalAnswer + multipleChoiceAnswers[keyTyped] + " &OR; "
    }
    setAnswerContent({ ...answerContent, content: finalAnswer });
    prop.callback(answerContent.content);
    for (let i = answersAmount + 3; i < Object.keys(multipleChoiceAnswers).length + 1; i++) {
      const nameProp = "answeroption" + i
      delete multipleChoiceAnswers[nameProp];
    }
  }

  function loadOldAnswerStuff(answers: stateObject, checks: stateObject) {
    let result = "";
    for (const key in answers) {
      const keyTyped = key as keyof typeof answers; // multipleChoiceAnswers.key same as multipleChoiceChecks.key
      if (checks[keyTyped]) {
        result += "&true;" + answers[keyTyped] + " &OR; ";
      } else if (!checks[keyTyped]) {
        result += answers[keyTyped] + " &OR; ";
      }
      setAnswerOldContent(result);
    }
    setAnswerOldContent(result);
  }

  useEffect(() => {
    updateAnswerStuff();
    getForms();
    if (prop.cardData && flag) {
      const answers = getOldAnswers(getCorrectAnswers(prop.cardData.content), getIncorrectAnswers(prop.cardData.content))
      const checks = getOldChecks(getCorrectAnswers(prop.cardData.content), getIncorrectAnswers(prop.cardData.content))
      setCheckValues(checks);
      setInputValues(answers)
      setAnswersAmount(getCorrectAnswers(prop.cardData.content).length + getIncorrectAnswers(prop.cardData.content).length);
      loadOldAnswerStuff(answers, checks);
      setAnswerContent({ ...answerContent, content: answerOldContent });
      setFlag(false);
    }
    // eslint-disable-next-line
  }, [multipleChoiceAnswers, multipleChoiceChecks, answerContent.content, answersAmount, answerOldContent]);

  const getForms = () => {
    let stuff: JSX.Element[] = [];
    for (let i = 1; i < answersAmount + 1; i++) {
      const nameProp = "answeroption" + i
      const keyForm = nameProp + "form";
      stuff.push(
        <div key={keyForm}>
          <Form.Label> Answer Option {i}: </Form.Label>
          <Col>
            <InputGroup>
              <InputGroup.Radio name="radiocheck" id={nameProp} defaultChecked={multipleChoiceChecks[nameProp]} onChange={handleCheckChange} />
              <Form.Control type="text" placeholder="Enter Answer" name={nameProp} defaultValue={multipleChoiceAnswers[nameProp]} onChange={handleInputChange} />
            </InputGroup>
          </Col>
        </div>
      );
    }
    for (let i = answersAmount + 1; i < answersAmount + 1; i++) {
      const nameProp = "answeroption" + i
      const keyForm = nameProp + "form";
      stuff.push(
        <div key={keyForm}>
          <Form.Label> Answer Option {i}: </Form.Label>
          <Col>
            <InputGroup>
              <InputGroup.Radio name="radiocheck" id={nameProp} onChange={handleCheckChange} />
              <Form.Control type="text" placeholder="Enter Answer" name={nameProp} onChange={handleInputChange} />
            </InputGroup>
          </Col>
        </div>
      );
    }
    return stuff;
  }

  const getDeleteButton = () => {
    if (answersAmount > 0) {
      return (
        <Button className="card-plus-minus m-2" variant="secondary" onClick={() => { setAnswersAmount(answersAmount - 1); deleteLastAnswer(); }}>
          <i className="bi bi-dash-circle"></i>
        </Button>
      );
    }
  }

  return (
    <>
      <Form.Group className="contentfield_multiplechoice">
        {getForms()}
        <Button className="card-plus-minus m-2" variant="secondary" onClick={() => setAnswersAmount(answersAmount + 1)}>
          <i className="bi bi-plus-circle"></i>
        </Button>
        {getDeleteButton()}
        <Form.Control disabled value={answerContent.content} name="textareaInput" className="d-none textfield" as="textarea" placeholder="Enter Answer" id={"content"} />
      </Form.Group>
    </>
  );

}

export default EditCardSingleAnswerComp;


