import React, { useState } from "react";
import { removeJWT } from "../utils/jwt";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { OverlayTrigger, Tooltip, Offcanvas, Badge } from "react-bootstrap";

function MainComponent() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleToggleOffCanvas = () => {
    setShow(!show);
  };

  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();
    removeJWT();
    navigate('/login');
  };

  const Sidebar = () => (
    <div className={`sidebar d-flex justify-content-between flex-column bg-success text-white py-3 ps-3 pe-2 vh-100`}>
      <div>
        <div className="p-2" onClick={() => navigate('/dashboard')}>
          <img src={logo} alt="Logo me-1 fs-5" style={{ width: "45px", height: "auto" }} />
        </div>
        <hr className="text-white mt-1" />
        <ul className="nav nav-pills flex-column mt-1">
          <OverlayTrigger placement={'right'} overlay={<Tooltip> Deck Overview </Tooltip>}>
            <li className="nav-item p-2 mt-3" onClick={() => navigate('/dashboard')}>
              <div className="p-2">
                <i className="bi bi-house-fill me-1 fs-4 m-1" />
              </div>
            </li>
          </OverlayTrigger>
          <OverlayTrigger placement={'right'} overlay={<Tooltip> Profile </Tooltip>}>
            <li className="nav-item p-2 mt-3" onClick={() => navigate('/dashboard/profile')}>
              <div className="p-2">
                <i className="bi bi-person-circle me-1 fs-4 m-1" />
              </div>
            </li>
          </OverlayTrigger>
          <OverlayTrigger placement={'right'} overlay={<Tooltip> Quiz </Tooltip>}>
            <li className="nav-item p-2 mt-3" onClick={() => navigate('/dashboard/quiz')}>
              <div className="p-2">
                <i className="bi bi-collection me-1 fs-4 m-1" />
              </div>
            </li>
          </OverlayTrigger>
        </ul>
      </div>
      <div>
        <hr className="text-white mt-1" />
        <ul className="nav nav-pills flex-column mt-3">
          <OverlayTrigger placement={'right'} overlay={<Tooltip> Logout </Tooltip>}>
            <li className="nav-item p-2 mt-3" onClick={handleLogout}>
              <div className="p-2">
                <i className="bi bi-door-closed-fill me-1 fs-4 m-1" />
              </div>
            </li>
          </OverlayTrigger>
        </ul>
      </div>
    </div>
  );

  const OffCanvas = () => (
    <Offcanvas className="offcanvas d-flex flex-column bg-success text-white vw-100" show={show} onHide={handleToggleOffCanvas}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <div className="p-2" onClick={() => { navigate('/dashboard'); handleToggleOffCanvas() }}>
            <img src={logo} alt="Logo me-1 fs-5" className="m-1" style={{ width: "45px", height: "auto" }} />
            LearnUp
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr className="text-white" />
      <Offcanvas.Body>
        <div className="d-flex justify-content-between flex-column">
          <div>
            <ul className="nav nav-pills flex-column">
              <li className="nav-item p-2 mt-3" onClick={() => { navigate('/dashboard'); handleToggleOffCanvas() }}>
                <div className="p-2">
                  <i className="bi bi-house-fill me-1 fs-4 m-1" >
                    <span className="m-2">Dashboard</span>
                  </i>
                </div>
              </li>
              <li className="nav-item p-2 mt-3" onClick={() => { navigate('/dashboard/profile'); handleToggleOffCanvas() }}>
                <div className="p-2">
                  <i className="bi bi-person-circle me-1 fs-4 m-1" >
                    <span className="m-2">Profile</span>
                  </i>
                </div>
              </li>
              <li className="nav-item p-2 mt-3" onClick={() => { navigate('/dashboard/quiz'); handleToggleOffCanvas() }}>
                <div className="p-2">
                  <i className="bi bi-collection me-1 fs-4 m-1">
                    <span className="m-2">Quiz</span>
                  </i>
                </div>
              </li>
              <li className="nav-item p-2 mt-3" onClick={handleLogout}>
                <div className="p-2">
                  <i className="bi bi-door-closed-fill me-1 fs-4 m-1">
                    <span className="m-2">Logout</span>
                  </i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas >
  );

  return (
    <>
      <div className="burger-menu">
        <Badge bg="success" pill>
          <i className={`bi bi-list fs-5 ${show ? ' open' : ''}`} onClick={handleToggleOffCanvas} />
        </Badge>
      </div>
      <Sidebar />
      <OffCanvas />
    </>
  );
}

export default MainComponent;
