import { Badge } from "react-bootstrap";
import { CardType } from "../utils/resources";
import { getTrimmedAnswers } from "../utils/cardanswerutils";
import React from "react";

export function AnswerBadgeComp(content: string, cardType: CardType): JSX.Element[] {
    let answersArray = content.split("&OR;");
    if (cardType !== CardType.Standard) {
        answersArray = answersArray.slice(0, -1);
    }

    return answersArray.map((answer, index) => (
        <Badge
            key={index}
            bg={cardType === CardType.Standard ? 'success' : answer.includes("&true;") ? 'success' : 'danger'}
            className="m-1"
        >
            <div>
                {getTrimmedAnswers(answer)}
            </div>
        </Badge>
    ));
}