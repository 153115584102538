import { createContext, useContext } from 'react'

// LOGININFO
interface LoginInfo {
  profileId: string
}
interface LoginContextType {
  loginInfo: LoginInfo | null
  setLoginInfo: (loginInfo: LoginInfo | null) => void
}
export const LoginContext = createContext<LoginContextType>({
  loginInfo: null,
  setLoginInfo: () => {}
})
export const useLoginContext = (): LoginContextType => useContext(LoginContext)

// BREADCRUMBITEM
export interface BreadcrumbItem {
  path: string;
  label: string;
}
interface BreadcrumbItemType {
  breadCrumb: BreadcrumbItem[] | null
  setBreadCrumb: (breadCrumb: BreadcrumbItem[] | null) => void
}
export const BreadCrumbContext = createContext<BreadcrumbItemType>({
  breadCrumb: null,
  setBreadCrumb: () => {}
})
export const useBreadCrumbContext = (): BreadcrumbItemType => useContext(BreadCrumbContext)

// TOASTMSG
export interface ToastMsg {
  header: string;
  msg: string;
}
interface ToastMsgType {
  toastMsg: ToastMsg | null
  setToastMsg: (toastMsg: ToastMsg | null) => void
}
export const ToastMsgContext = createContext<ToastMsgType>({
  toastMsg: null,
  setToastMsg: () => {}
})
export const useToastMsgContext = (): ToastMsgType => useContext(ToastMsgContext)
